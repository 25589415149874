import React, {  } from 'react';
import { Link } from "react-router-dom"; 
import { RedtonsComponent } from "../components/RedtonsComponent";
import IncidentForm1Component from "./IncidentForm1Component";
import Accordion from 'react-bootstrap/Accordion'
import { Navigate } from 'react-router-dom'


export default class BasicIncidentFormComponent extends RedtonsComponent {
  
  //let http = new Http();
 
	constructor(props) {
		super(props);
		
		//console.log("Basic.....",this.auth.getUser());
		
		const date = new Date().toISOString().substr(0, 10);
		const time = new Date().toISOString().substr(11, 5);
		
		this.state = {
		    date:date,
		    time:time,
			data:{
				occurrence_datetime: date+" "+time+":00",
				occurrence_status:'Draft',
				form_type:'basic',
				step:"step1"
			},
			wasValidated:false,
			errors:{},
			errorMessage:'',
		    successMessage:'',
		    update:false,
		    isRedirect:false
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
	}
	
	
	
	handleSubmit(event) {
	      event.preventDefault();
	      //console.log(this.state.data);
	     
	      //this.setState({isRedirect:1});
	      //return false;
	      this.http.post("incident",this.state.data)
			.then((res) => {
				this.setState({wasValidated:true});
				if (res.data.id) {
					//this.setState({update:true,id:res.data.id});
					this.setState({isRedirect:res.data.id});
					console.log("success")
				}
				else{
					  
					  return false;
				}
			})
			.catch((error) => {
				if (error.response) {
					//console.log(error.response.data);
					this.setState({errors:error.response.data});
					this.setState({wasValidated:true});
					this.loader.hide();
				}
				return false;
			});
	  }
	
	
	handleChange(event) {

		   const value = event.target.value;
		   const name = event.target.name;
		   const data = this.state.data;
		   data[name] = value;
		   this.setState({
		      data:data
		   });
	  }
	
	handleDateTimeChange(event) {

		   const value = event.target.value;
		   const name = event.target.name;
		   
		   this.setState({
			      [name]: value
			   });
		   
		   const data = this.state.data;
		   data['datetime'] = this.state.date+" "+this.state.time+":00";
		   
		   this.setState({
			      data:data
			   });
	  }
	
  render() {
	  return (
			  <div id="content" className="app-content">
				
			    <div className="container">
			
				    <div className="row justify-content-center">
				    
				   
				    <div className="col-xl-12">
				    
				    {this.state.isRedirect && (<Navigate to={{pathname: "/basicIncident/"+this.state.isRedirect}} />)}
				    
				    
					    <ul className="breadcrumb">
					    <li className="breadcrumb-item"><Link to="/basicIncident">BRIEF INCIDENTS</Link></li>
					    </ul>
					 
					    <h1 className="page-header">
					    	BRIEF INCIDENT FORM
					    </h1>
					    
					    <div className="progress" style={{display:"none"}}>
				    	  <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" style={{width: '1%'}}>0%</div>
				    	</div>
				    	
				    	<br/>
				    	
					    <form className="was-validated1" onSubmit={this.handleSubmit}>
						    <Accordion defaultActiveKey="1">
						    	<IncidentForm1Component form={this}/>
						    </Accordion>
					    </form>	 	
					    	
					    	
					    
					</div>
				
				    </div>
			
			    </div>
		
		    </div>
			
	  );
  }
}
