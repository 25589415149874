import React, {  } from "react";
import { RedtonsComponent } from "./RedtonsComponent";
import { Navigate } from 'react-router-dom'


export default class LoginComponent  extends RedtonsComponent {
  
  constructor(props) {
    super(props);
   
    this.isLoggedIn = false;
    
    /*if(this.auth.isLoggedIn()){
    	this.isLoggedIn = true;
    }*/
    
    //console.log("Login.....",this.auth.isLoggedIn());
    
    this.state = {
      username: '',
      password:'',
      isLoggedIn:this.isLoggedIn,
      isLoginFailed:false,
      errors:{}
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }
  
  handleChange(event) {

	   const value = event.target.value;
	   const name = event.target.name;
	   
	   this.setState({
	      [name]: value
	   });
  }
  
  
  async handleSubmit(event) {
      event.preventDefault();
      
      await this.auth.login({username:this.state.username,password:this.state.password});
      
      if(this.auth.isLoggedIn()){
      	this.setState({isLoggedIn:true});
      }
      else{
    	  this.setState({isLoginFailed:true});
      }
      
      this.loader.hide();
  }
   
  
 
  render() {
	  return (
			    <div id="app" className="app app-full-height app-without-header">
			      <div className="login">
			        <div className="login-content">
			        
			        	{this.auth.isLoggedIn() && (<Navigate to={{pathname: "/dashboard"}} />)}
			       
			          <form className="was-validated1" onSubmit={this.handleSubmit}>
			          
			            <div><img src="assets/img/airmatrix_login_logo.png" style={{width:"360px","margin-bottom":"20px"}} alt="AirMatrix"/></div>
			            
			            {this.state.isLoginFailed && 
			            <div className="alert alert-danger fade show p-3">
				            <h4 className="alert-heading">Error!</h4>
				            <p>Your username or password is not correct!</p>
				        </div>
			            }
			            
			            <div className="mb-3">
			              <label className="form-label">
			                Username <span className="text-danger">*</span>
			              </label>
			              <input type="text" className="form-control form-control-lg bg-white bg-opacity-5" name="username" value={this.state.username} onChange={this.handleChange} />      
			           </div>
			              
			            <div className="mb-3">
			            
			              <div className="d-flex">
			              
			                <label className="form-label">
			                  Password <span className="text-danger">*</span>
			                </label>
			                
			                <a href="/forgot" className="ms-auto text-white text-decoration-none text-opacity-50">Forgot password?</a>
			              </div>
			              
			              <input type="password" className="form-control form-control-lg bg-white bg-opacity-5" name="password" value={this.state.password} onChange={this.handleChange} />
			             
			            </div>
			            
			            
			            <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">
			              Sign In
			            </button>
			              
			            <div className="text-center text-white text-opacity-50">
			              Don't have an account yet? &nbsp; 
			               <a href="/register">Sign up</a>.
			            </div>
			               
			          </form>
			        </div>
			      </div>
		                <div className="col-xl-12 powered">
          				<h3>Partners</h3>
          				<ul>
            				<li>
              					<img src="/assets/img/copa_logo.png" alt="" />
            				</li>
            				<li>
              					<img src="/assets/img/airmatrix_logo.png" alt="" />
            				</li>
            				<li>
              					<img src="/assets/img/aerial_evolution_logo.png" alt="" />
            				</li>
          				</ul>
        			</div>
			    </div>
			  );
	  
  }
}

