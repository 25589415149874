// WithNav.js (Stand-alone Functional Component)
import React, {  } from 'react';
import { Outlet } from "react-router";

import HeaderComponent from "./HeaderComponent";
//import SidebarComponent from "./SidebarComponent";
import FooterComponent from "./FooterComponent";

export default function WithNav(props){
	

  return (
    <>
      <HeaderComponent></HeaderComponent>
      
      <Outlet />
      
      <FooterComponent current_uri={props.current_uri}></FooterComponent>
    </>
  );
};

//<SidebarComponent></SidebarComponent>