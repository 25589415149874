import React, {  } from 'react';
import { Http } from "./http";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const JWT_TOKEN = 'JWT_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const USER = 'USER';

	
export default class Auth{

	constructor(http = null){
		if(http){
			this.http = http;
		}else{
			this.http = new Http();
		}
	}

	async login(data){
		return await this.http.post("auth/login",data)
			.then((res) => {
				if (res.data.token) {
					//let data = {profile:res.data.profi,token:{access:res.data.token,refresh:res.data.token}}
					//console.log("login res :", res.data);
					this._doLoginUser(res.data);
					return true;
				}
				else{
					return false;
				}
			})
			.catch((error) => {
				if (error.response) {
					//setIsLoggedIn(true);
				}
				
				return false;
			});
	}
	
	refreshToken(){
		return this.http.get("login")
			.then((res) => {
				if (res.data.token) {
					//console.log("refresh token")
					let token = {data:{profile:res.data.user,token:{access:res.data.token,refresh:res.data.token}}}
					
					this._setAccessToken(token);
					//this.navigate("/dashboard");
					return true;
				}
				else{
					return false;
				}
			})
			.catch((error) => {
				if (error.response) {
					//setIsLoggedIn(true);
				}
				
				return false;
			});
	}
	
	
	isLoggedIn() {
	    return !!Auth.getAccessToken();
	}
	
	isUserRole() {
		let user = JSON.parse(sessionStorage.getItem(USER));
	    return user['role'];
	}
	
	getUser() {
	    return JSON.parse(sessionStorage.getItem(USER));
	}
	
	_setAccessToken(token) {
		sessionStorage.setItem(JWT_TOKEN, token);
	}
	
	static getAccessToken() {
		try{
			const token = sessionStorage.getItem(JWT_TOKEN);
			
			if(token){
				let decodedJwt = jwt_decode(token);
				if(decodedJwt.exp * 1000 < Date.now()) {
					Auth.logout();
					return false;
				}
				else{
					return token;
				}
			}
			else{
				return false;
			}
		}
		catch(err) {
			Auth.logout();
			return false;
		}
	}
	
	getRefreshToken() {
	    return sessionStorage.getItem(REFRESH_TOKEN);
	}

	_doLoginUser(data) {
		sessionStorage.setItem(JWT_TOKEN, data.token.access);
		sessionStorage.setItem(REFRESH_TOKEN, data.token.refresh);
		sessionStorage.setItem(USER, JSON.stringify(data.profile));
	}
	
	
	 static logout() {
		sessionStorage.removeItem(JWT_TOKEN);
		sessionStorage.removeItem(REFRESH_TOKEN);
		sessionStorage.removeItem(USER);
		//this.navigate("/login");
		
		//return <Navigate to="/login" replace />;
	}
	
	PrivateRoute = ({ children }) => {
		
		if (!this.isLoggedIn()) {
		    return <Navigate to="/login" replace/>;
		}
		else{
			return children;
		}
		
	}
	
	PrivateUserRoute = ({ children }) => {
		
		if (!this.isLoggedIn()) {
		    return <Navigate to="/login" replace/>;
		}
		
		if (this.isUserRole() === "2") {
			return children;
		}
		else{
			return <Navigate to="/404" replace/>;
		}
		
	}
	
	PrivateAdminRoute = ({ children }) => {
		
		if (!this.isLoggedIn()) {
		    return <Navigate to="/login" replace/>;
		}
		
		if (this.isUserRole() === "1") {
			return children;
		}
		else{
			return <Navigate to="/404" replace/>;
		}
		
	}
	
	
}



