import React, {  } from 'react';
import { RedtonsComponent } from "../components/RedtonsComponent";
import Accordion from 'react-bootstrap/Accordion'


export default class IncidentForm1Component extends RedtonsComponent {
  
  //let http = new Http();
 
	constructor(props) {
		super(props);
		
		this.profile = this.auth.getUser();
	    this.userRole = this.profile['role'];
		
		this.form = this.props.form;
		
		//this.form.setState({date:new Date()});
	    //console.log("Form 1",OCCURANCE_CATEGORY);
	}
	
	
  render() {
	  return (
			  <Accordion.Item eventKey="1">
	        	<Accordion.Header>1. Report Details</Accordion.Header>
		        <Accordion.Body className="row mb-n3">
		        
		        <div className="mb-3 col-xl-6">
			    	<label className="form-label">Occurrence Date <span className="text-danger">*</span></label>
			    	<input type="date" className={"form-control bg-white bg-opacity-5 "+ this.form.isValid('date')}  name="date" value={this.form.state.date} onChange={this.form.handleDateTimeChange} />
			    	{!this.form.isError('date') && <div className="invalid-feedback">{this.form.getErrorMessage('date')}</div>}
			    </div>
			    
			    <div className="mb-3 col-xl-6">
			    	<label className="form-label">Occurrence Time <span className="text-danger">*</span></label>
			    	<input type="time" className={"form-control bg-white bg-opacity-5 "+ this.form.isValid('time')}  name="time" value={this.form.state.time} onChange={this.form.handleDateTimeChange} />
			    	{!this.form.isError('time') && <div className="invalid-feedback">{this.form.getErrorMessage('time')}</div>}
			    </div>
			    
			    
			    <div className="mb-3 col-xl-6">
				    <div className="mb-3"><span className="form-label">Occurrence Status <span className="text-danger">*</span></span></div>
				    	<div className={"form-group mb-3 "+ this.form.isValid('occurrence_status')}>
					    	<div className="form-check form-check-inline">
					    		<input className="form-check-input" type="radio" id="inlineCheckbox1" value="Draft" name="occurrence_status" checked={this.form.state.data.occurrence_status === "Draft"} onChange={this.form.handleChange} />
					    		<label className="form-check-label" htmlFor="inlineCheckbox1">Draft</label>
					    	</div>
						    <div className="form-check form-check-inline">
						    	<input className="form-check-input" type="radio" id="inlineCheckbox2" value="Publish for Investigation" name="occurrence_status" checked={this.form.state.data.occurrence_status === "Publish for Investigation"} onChange={this.form.handleChange} />
						    	<label className="form-check-label" htmlFor="inlineCheckbox2">Publish for Investigation</label>
						    </div>
						    <div className="form-check form-check-inline">
						    	<input className="form-check-input" type="radio" id="inlineCheckbox3" value="Publish for Final Report" name="occurrence_status" checked={this.form.state.data.occurrence_status === "Publish for Final Report"} onChange={this.form.handleChange} />
						    	<label className="form-check-label" htmlFor="inlineCheckbox3">Publish for Final Report</label>
						    </div>
				    </div>
				    
				    {!this.form.isError('occurrence_status') && <div className="invalid-feedback">{this.form.getErrorMessage('occurrence_status')}</div>}
			    </div>
		    	
			    {this.userRole === "2" &&
				    <div className="mb-3">
				    	<button type="submit" className="btn btn-outline-theme active" value="2" onClick={this.form.handleOnSave}>Save & Continue</button>
				    </div>
			    }
		        
		        </Accordion.Body>
	        </Accordion.Item>
	  );
  }
}
