import React, {  } from 'react';
import { Link } from "react-router-dom"; 
import { RedtonsComponent } from "./RedtonsComponent";

//eslint-disable-next-line
Object.defineProperty(String.prototype, 'capitalize', {
	  value: function() {
	    return this.charAt(0).toUpperCase() + this.slice(1);
	  },
	  enumerable: false
	});


export default class DashboardComponent  extends RedtonsComponent {

	constructor(props) {
	    super(props);
	    
	    this.profile = this.auth.getUser();
	    this.userRole = this.profile['role'];
	    
	    this.state = {
	    	statics:{
	    		basic:{},
	    		advance:{},
	    		total_users:0,
	    	},
	    	activities:[],
	    	incidents:[]
	    };
	    
	    //console.log("Dashboard.....",this.auth.isLoggedIn());
	     
		this.activities = [
			{id:1,activity:"1 new incident publish for investigation.",datetime:"1 min ago",type:"INCIDENT",uri:""},
			{id:2,activity:"You have 1 new recive incident from user 101.",datetime:"just now",type:"INCIDENT",uri:""},
			{id:3,activity:"1 new incident publish for investigation.",datetime:"just now",type:"INCIDENT",uri:""},
			{id:4,activity:"You have 1 new recive incident from user 101.",datetime:"just now",type:"INCIDENT",uri:""},
			{id:5,activity:"1 new incident publish for investigation.",datetime:"1 min ago",type:"INCIDENT",uri:""},
			{id:6,activity:"You have 1 new recive incident from user 101.",datetime:"just now",type:"INCIDENT",uri:""},
			{id:7,activity:"1 new incident publish for investigation.",datetime:"just now",type:"INCIDENT",uri:""},
			{id:8,activity:"You have 1 new recive incident from user 101.",datetime:"just now",type:"INCIDENT",uri:""},
			{id:9,activity:"1 new incident publish for investigation.",datetime:"just now",type:"INCIDENT",uri:""},
			{id:10,activity:"You have 1 new recive incident from user 101.",datetime:"just now",type:"INCIDENT",uri:""}
		];
		
		this.activity_badge = false;
		
	}
	
	componentDidMount(){
		  this.getStatics();
		  this.getActivites();
		  this.getIncidentList();
	}
	  
	

	getStaticCount(type,key){
		
		return this.state.statics[type][key]
	}
	
	getStatics(){
		  
		   this.http.get("statics")
			.then((res) => {
				//console.log("Static : ",res.data);
				
				this.setState({statics: res.data});
			})
			.catch((error) => {
				this.loader.hide();
				return false;
			});
	}
	
	getActivites(){
		   this.setState({activities:[]});
		   this.http.get("activity")
			.then((res) => {
				//console.log("Activity : ",res.data);
				
				this.setState({activities: res.data});
			})
			.catch((error) => {
				this.loader.hide();
				return false;
			});
	}
	
	getIncidentList(){
		
		 let params = {
	    	  occurrence_status:'Draft'
	      };
		  
		  this.setState({incidents:[]});
		  this.http.get("incident",params)
			.then((res) => {
				this.setState({incidents: res.data.data});
			})
			.catch((error) => {
				this.loader.hide();
				if (error.response) {
					this.setState({errors:error.response.data});
					
				}
				return false;
			});
	  }
	
	get_activity_badge(){
		if(this.activity_badge){
			this.activity_badge = false;
			return 'bg-theme';
		}
		else{
			this.activity_badge = true;
			return 'bg-white';
		}
	}
	
	
	getDateFormt(str){
		  return str.replace("T"," ").slice(0, -8) 
	  }
	
	render(){
		  return (
		    <>
		      <button className="app-sidebar-mobile-backdrop" data-toggle-target=".app" data-toggle-class="app-sidebar-mobile-toggled"/>
		    	  
		      <div id="content" className="app-content">
		      
		        <div className="row">
		        
		        {this.userRole === "2" && 
		          <div className="col-xl-3 col-lg-6">
		            <div className="card mb-3">
		            
		              <div className="card-body">
		                <div className="d-flex fw-bold small mb-0">
		                  <h4 className="flex-grow-1">BRIEF DRAFT INCIDENTS</h4>
		                  <h4 className="flex-grow-1">{this.state.statics['basic']['Draft']}</h4>
		                </div>
		              </div>
		              
		              <div className="card-arrow">
		                <div className="card-arrow-top-left" />
		                <div className="card-arrow-top-right" />
		                <div className="card-arrow-bottom-left" />
		                <div className="card-arrow-bottom-right" />
		              </div>
		            </div>
		          </div>
		        }
		          
		          <div className="col-xl-3 col-lg-6">
		            <div className="card mb-3">
		            
		              <div className="card-body">
		                <div className="d-flex fw-bold small mb-0">
		                  <h4 className="flex-grow-1">BRIEF COMPLETED INCIDENTS</h4>
		                  <h4 className="flex-grow-1">{this.state.statics['basic']['Publish for Final Report']}</h4>
		                </div>
		              </div>
		              
		              <div className="card-arrow">
		                <div className="card-arrow-top-left" />
		                <div className="card-arrow-top-right" />
		                <div className="card-arrow-bottom-left" />
		                <div className="card-arrow-bottom-right" />
		              </div>
		            </div>
		          </div>
		          
		          {this.userRole === "2" && 
		          <div className="col-xl-3 col-lg-6">
		            <div className="card mb-3">
		            
		              <div className="card-body">
		                <div className="d-flex fw-bold small mb-0">
		                  <h4 className="flex-grow-1">DETAILED DRAFT INCIDENTS</h4>
		                  <h4 className="flex-grow-1">{this.state.statics['advance']['Draft']}</h4>
		                </div>
		              </div>
		              
		              <div className="card-arrow">
		                <div className="card-arrow-top-left" />
		                <div className="card-arrow-top-right" />
		                <div className="card-arrow-bottom-left" />
		                <div className="card-arrow-bottom-right" />
		              </div>
		            </div>
		          </div>
		          }
		          
		          <div className="col-xl-3 col-lg-6">
		            <div className="card mb-3">
		            
		              <div className="card-body">
		                <div className="d-flex fw-bold small mb-0">
		                  <h4 className="flex-grow-1">DETAILED COMPLETED INCIDENTS</h4>
		                  <h4 className="flex-grow-1">{this.state.statics['advance']['Publish for Final Report']}</h4>
		                </div>
		              </div>
		              
		              <div className="card-arrow">
		                <div className="card-arrow-top-left" />
		                <div className="card-arrow-top-right" />
		                <div className="card-arrow-bottom-left" />
		                <div className="card-arrow-bottom-right" />
		              </div>
		            </div>
		          </div>
		          
		          {this.userRole === "1" && 
			          <div className="col-xl-3 col-lg-6">
			            <div className="card mb-3">
			            
			              <div className="card-body">
			                <div className="d-flex fw-bold small mb-0">
			                  <h4 className="flex-grow-1">USERS</h4>
			                  <h4 className="flex-grow-1">{this.state.statics['total_users']}</h4>
			                </div>
			              </div>
			              
			              <div className="card-arrow">
			                <div className="card-arrow-top-left" />
			                <div className="card-arrow-top-right" />
			                <div className="card-arrow-bottom-left" />
			                <div className="card-arrow-bottom-right" />
			              </div>
			            </div>
			          </div>
		          }
		          
		         
		        </div>      
		          
			    <div className="row">      
		          
			    {this.userRole === "2" &&
			    	
		          <div className="col-xl-12">
		            <div className="card mb-3">
		              <div className="card-body">
		                <div className="d-flex fw-bold small mb-3">
		                  <span className="flex-grow-1">INCIDENT MANAGEMENT</span>
		                </div>
		                <div className="table-responsive">
		                  <table className="w-100 mb-0 small align-middle text-nowrap">
		                    <tbody>
		                       {this.state.incidents.map((row, index) => (
							      <tr key={row.id}>
							        <th scope="row"><span className="badge bg-secondary">{(index+1)}</span></th>
							        <td>{row.form_type.capitalize()}</td>
							        <td>{this.getDateFormt(row.occurrence_datetime)}</td>
							        <td>{row.occurrence_status}</td>
							        <td>
							           {(row.form_type === "basic") &&
							        	   <Link className="btn btn-default btn-sm" to={"/basicIncident/"+row.id}><i className="bi bi-pencil" /></Link>    
							        	} 
							           
							           {(row.form_type === "advance") &&
							        	   <Link className="btn btn-default btn-sm" to={"/incident/"+row.id}><i className="bi bi-pencil" /></Link>    
							        	}  
							        </td>
							      </tr>
							    ))}
		                     
		                    </tbody>
		                  </table>
		                </div>
		              </div>
		              <div className="card-arrow">
		                <div className="card-arrow-top-left" />
		                <div className="card-arrow-top-right" />
		                <div className="card-arrow-bottom-left" />
		                <div className="card-arrow-bottom-right" />
		              </div>
		            </div>
		          </div>
			    }
		          
		          
		          
			    {this.userRole === "1" &&  
		          <div className="col-xl-12">
		            <div className="card mb-3">
		              <div className="card-body">
		                <div className="d-flex fw-bold small mb-3">
		                  <span className="flex-grow-1">ACTIVITY LOG</span>
		                </div>
		                <div className="table-responsive">
		                  <table className="table table-striped table-borderless mb-2px small text-nowrap">
		                    <tbody>
		                    
		                    {this.state.activities.map(row => (
		                            
		                          
		                      <tr key={row.id}>
		                      
		                        <td>
		                          <span className="d-flex align-items-center">
		                            <i className="bi bi-circle-fill fs-6px text-theme me-2" />
		                            {row.type === 'create' && "1 new incident created"}
		                            {row.type === 'update' && "1 new incident updated"}
		                          </span>
		                        </td>
		                        
		                        <td>
		                          <small>{this.getDateFormt(row.created_at)}</small>
		                        </td>
		                        
		                       
		                        
		                        <td>
						        	<Link className="text-decoration-none text-white" to={"/incident/"+row.incident_id}><i className="bi bi-eye" /></Link>    
						        
		                        </td>
		                        
		                      </tr>
		                      
		                    ))} 
		                    
		                    </tbody>
		                  </table>
		                </div>
		              </div>
		              <div className="card-arrow">
		                <div className="card-arrow-top-left" />
		                <div className="card-arrow-top-right" />
		                <div className="card-arrow-bottom-left" />
		                <div className="card-arrow-bottom-right" />
		              </div>
		            </div>
		          </div>
			    }
			    
		        </div>
		        
		        
		        
		        <div className="col-xl-12 powered" style={{display:"none"}}>
		    		<h3>Partners</h3>
		    		<ul>
		    			<li><img src="/assets/img/copa_logo.png" alt=""/></li>
		    			<li><img src="/assets/img/airmatrix_logo.png" alt=""/></li>
		    			<li><img src="/assets/img/aerial_evolution_logo.png" alt=""/></li>
		    		</ul>
	    		</div>
	    		
	    		
	    		
		      </div>
		      
		      
		      
		    </>
		  );
       }
}
