import React, {  } from 'react';
import { Link } from "react-router-dom"; 
import Auth from "../services/auth.service";
import { RedtonsComponent } from "../components/RedtonsComponent";



export default class HeaderComponent extends RedtonsComponent {

	
	constructor(props) {
	    super(props);
	    this.profile = this.auth.getUser();
	    this.userRole = this.profile['role'];
	    
	   
	    this.pathname = window.location.pathname;
	    this.splitLocation = this.pathname.split("/");
	    
	    this.setCurrentUri = this.setCurrentUri.bind(this);
	   
	   
	    
	    
	}
	
	componentDidMount(){
		this.setState({current_uri:this.splitLocation[1]})
	}
	
	setCurrentUri(){
		this.pathname = window.location.pathname;
		
	    this.splitLocation = this.pathname.split("/");
	    
	    this.setState({current_uri:this.splitLocation[1]})
	    
	    localStorage.setItem('current_uri', this.splitLocation[1]);
	    
	}
	
	
	
	logout(){
		Auth.logout();
	}
	render(){
		  return (
		    <div id="header" className="app-header">
		    
		      
		      <div className="desktop-toggler" style={{display:'none'}}>
		        <button type="button" className="menu-toggler" data-toggle-class="app-sidebar-collapsed" data-dismiss-class="app-sidebar-toggled" data-toggle-target=".app">
		          <span className="bar" />
		          <span className="bar" />
		          <span className="bar" />
		        </button>
		      </div>
		      
		      <div className="mobile-toggler">
		        <button type="button" className="menu-toggler" data-toggle-class="app-sidebar-mobile-toggled" data-toggle-target=".app">
		          <span className="bar" />
		          <span className="bar" />
		          <span className="bar" />
		        </button>
		      </div>
		      
		      
		      <div className="brand" style={{display:"none"}}>
		      	<Link to="/dashboard" className="brand-logo">
		      		
		          <img src="/assets/img/logo.png" alt="Airmatrix"/>
		        </Link>
		      </div>
		      
		      <div className="menu first" style={{"marginLeft":"15px"}}>
		      
		     
			      <div onClick={this.setCurrentUri} className={this.state.current_uri === "dashboard" ? "menu-item active" : "menu-item"}>
			      		<Link to="/dashboard" className="menu-link">
					      <span className="menu-icon">
					        <i className="bi bi-cpu" />
					      </span>
					      <span className="menu-text">DASHBOARD</span>
				      </Link>
				  </div>
				  
				  <div onClick={this.setCurrentUri} className={this.state.current_uri === "basicIncident" ? "menu-item active" : "menu-item"}>
					<Link to="/basicIncident" className="menu-link">
						<span className="menu-icon">
						<i className="bi bi-bug-fill" />
						</span>
						<span className="menu-text">BRIEF INCIDENTS</span>
					</Link>
				  </div>
				  
				 
				  <div onClick={this.setCurrentUri} className={this.state.current_uri === "incident" ? "menu-item active" : "menu-item"}>
					<Link to="/incident" className="menu-link">
						<span className="menu-icon">
						<i className="bi bi-bug-fill" />
						</span>
						<span className="menu-text">DETAILED INCIDENTS</span>
					</Link>
				  </div>
				
				  
				  {this.userRole === "1" &&
					  
					  <div onClick={this.setCurrentUri} className={this.state.current_uri === "user" ? "menu-item active" : "menu-item"}>
					  	  <Link to="/user" className="menu-link">
						      <span className="menu-icon">
						        <i className="bi bi-person-lines-fill" />
						      </span>
						      <span className="menu-text">USERS</span>
					      </Link>
					  </div>
				  }
				  
				  <div className="menu-item" style={{'display':'none'}}>
				  	  <Link to="/settings" className="menu-link">
					      <span className="menu-icon">
					        <i className="bi bi-gear" />
					      </span>
					      <span className="menu-text">SETTINGS</span>
				      </Link>
				  </div>
				  
				 
			  
			  </div>
		     
			 
			  
			
		      <div className="menu">
		        <div className="menu-item">
		        
		          <Link to="/profile"  className="menu-link ">
		            <div className="menu-img online">
		              <img src="/assets/img/user/profile.png" height="60px" alt="Profile"/>
		            </div>
		            <div className="menu-text d-sm-block d-none">
		              <span>
		                {this.profile.first_name} {this.profile.last_name}
		              </span>
		            </div>
		          </Link>
		        </div>
		        
		        
		        <div className="menu-item">
		        	<Link to="/login" replace className="menu-link" onClick={()=> this.logout()}>
			        	<div className="menu-icon"><i className="bi bi-power nav-icon"></i></div>
			        </Link>
		        </div>
		        
		      </div>
		      
		      
		    </div>
		  );
	}
}




            
            
            
