import React, {  } from 'react';




const MyTextInput = (props) => {

	  const {row,form,step,handleChange,children,subField,parent,index, parentName} = props;
	  
	  let value = '';
	  //console.log("Multi row : ", form.state.data[step][row.name], multi);
	  
	  if(parentName !== undefined){
		  //console.log("Multi row : ", form.state.data[step][parentName], index);
		  if(form.state.data[step][parentName].length > index){
			  value = form.state.data[step][parentName][index][row.name];
		  }else{
			  value = '';
		  }
	  }
	  else{
		  value = form.state.data[step][row.name];
	  }
	  
	  
	
	  const isValid = ""; //form.isValid(step+"."+row.name);
	  const isError = form.isError(step+"."+row.name);
	  const errorMessage = form.getErrorMessage(step+"."+row.name);
	  
	  
	  /*function isArray (o) {
		    return typeof o === "object" && o.length !== undefined;
	  }*/
	  
	  
	  function handleCheck(val) {
		  	//value get from parent
		     if (value !== undefined){
		    	 return value.includes(val)
		     }
		     else{
		    	 return false;
		     }
	  }
	  
	  
	  let rowClass = row.class;
	  
	  if(row.hr === "false"){
		  rowClass = "mb-3 "+rowClass;
	  }else{
		  rowClass = "mb-1 "+rowClass;
	  }
	  
	  
	  function parentHandleCheck(paren_value, val) {
	    	
		     if (paren_value !== undefined){
		    	 return paren_value.includes(val)
		     }
		     else{
		    	 return false;
		    	 //console.log("Handle check : ",row.name," : ",value);
		     }
	   }
	  
	  if(subField && parent){
		  const parent_value = form.state.data[step][parent.name];
		  
		  //console.log("Parent : ", parent_value,parent.values[index].value);
		  //rowClass = rowClass + " hide";
		  row.hr = "false";
		  
		  if(!parentHandleCheck(parent_value,parent.values[index].value)){
			  return <></>;
		  }
	  }
	  
	  
	  if(row.type === "display"){
		  return (
				  <>
				  <div className={rowClass}>
				    	<h3>{row.title}</h3>
				  </div>
				  {row.hr === "true" && <hr/>}
				  
				  {children &&
					  <>
					  {children}
					  </>
				  }
				  </>
		  )
	  }
	  else if(row.type === "incremental"){
		  return <></>;
		  /*return (
				  <>
				  <div className={rowClass}>
				    	<h3>{row.title} <span className="text-danger">-</span> | <span className="text-danger">+</span></h3>
				  </div>
				  {row.hr == "true" && <hr/>}
				  
				  {children &&
					  <>
					  {children}
					  </>
				  }
				  </>
		  )*/
	  }
	  else if(row.type === "textarea"){
		  return (
				  <>
				  <div className={rowClass}>
				    	<label className="form-label">{row.title} 
				    		{row.required === "true" && <span className="text-danger">*</span> }
				    	</label>
				    	<textarea type="text" className={"form-control bg-white bg-opacity-5 "+ isValid}  id={index} name={row.name} onChange={handleChange} value={value}></textarea>
				    	{!isError && <div className="invalid-feedback display">{errorMessage}</div>}
				    	
				  </div>
				  {row.hr === "true" && <hr/>}
				  
				  {children &&
					  <>
					  {children}
					  </>
				  }
				  </>
				  
		  )
	  }
	  else if(row.type === "text"){
		  return (
				  <>
				  <div className={rowClass}>
				    	<label className="form-label">{row.title} 
				    	{row.required === "true" && <span className="text-danger">*</span> }
				    	</label>
				    	<input type="text" className={"form-control bg-white bg-opacity-5 "+ isValid}  id={index} name={row.name} value={value} onChange={handleChange} />
				    	{!isError && <div className="invalid-feedback display">{errorMessage}</div>}
				    	
				  </div>
				  {row.hr === "true" && <hr/>}
				  
				  {children &&
					  <>
					  {children}
					  </>
				  }
				  </>
				  
		  )
	  }
	  else if(row.type === "select"){
		  return (
				  <>
				  <div className={rowClass}>
			    	<label className="form-label">{row.title} 
			    	{row.required === "true" && <span className="text-danger">*</span> }
			    	</label>
			    	
			    	<select className={"form-select bg-white bg-opacity-5 "+ isValid} index={index} name={row.name} onChange={handleChange} value={value}>
			    	
			    	  <option value="">SELECT</option>
			    	  
			    	  {row.values.map((e) => (
			    			  <option key={e.id}>{e.value}</option>
			    	  ))}
			    	  
			    	</select>
			    	
			    	{!isError && <div className="invalid-feedback display">{errorMessage}</div>}
			    </div>
			    {row.hr === "true" && <hr/>}
			    
			    {children &&
					  <>
					  {children}
					  </>
				  }
			    </>
		  )
	  }
	  else if(row.type === "radio"){
		  return (
				  
				  <>
				  
				  <div className={rowClass}>
				  		<div className="mb-3">
				  			<span className="form-label">{row.title} 
				  			{row.required === "true" && <span className="text-danger">*</span> }
				  			</span>
				  		</div>
				  
				  		<div className={isValid}>
				  		
				  			{row.values.map((e) => (
				  					
				  			<div className="form-check form-check-inline mb-3" key={e.id}>
				  				<input className="form-check-input" type="radio" id={e.id} index={index} name={row.name} value={e.value} checked={value===e.value} onChange={handleChange}/>
				  				<label className="form-check-label" htmlFor={e.id}>{e.value}</label>
				  			</div>
				  			
				  			))}
				 
				  		</div>
				  		
				  		{!isError && <div className="invalid-feedback display">{errorMessage}</div>}
				  </div>
				  {row.hr === "true" && <hr/>}
				  
				  
				  {children &&
					  <>
					  {children}
					  </>
				  }
				  
				  </>
				  
		  )
	  }
	  else if(row.type === "checkbox"){
		  return (
				  <>
				  <div className={rowClass}>
				  
					  <div className="mb-12 col-xl-12 full-label">
					  	<span className="form-label">{row.title} 
					  	{row.required === "true" && <span className="text-danger">*</span> }
					  	</span>
					  </div>
					  
				      <div className={"row px-3" + isValid}>
				      
				      
				      {row.values.map((e) => (
				    			<div className="form-check col-xl-6 mb-3" key={e.id}>
						    		<input className="form-check-input" type="checkbox" id={e.id} value={e.value} index={index} name={row.name} checked={handleCheck(e.value)} onChange={handleChange} />
						    		<label className="form-check-label" htmlFor={e.id}>{e.value}</label>
						    	</div>
				    	     ))
				    	   }
				      {!isError && <div className="invalid-feedback display">{errorMessage}</div>}
				      </div>
			    	
				      
				      
				  </div>
				  
				  {row.hr === "true" && <hr/>}
				 
				  
				  {children &&
					  <>
					  {children}
					  </>
				  }
				  </>
		  )
	  }
	  else{
		  return (
				  <></>
		 )
	  }
}


export default MyTextInput;