//import React, {  } from 'react';
import { API_URL } from "../config";
//import { Navigate,useNavigate } from "react-router-dom";
import Auth from "./auth.service";
import axios from "axios";

import loader from "../components/LoaderComponent";

//let navigate = useNavigate();

/*const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    if (status === UNAUTHORIZED) {
    	//console.log(error);
    	//Auth.logout();
    	//window.location.reload(); 
    	return false;
      //dispatch(userSignOut());
    }
    return Promise.reject(error);
 }
);*/


export class Http {
	
	
	constructor(loaderDisplay=true){
		this.loaderDisplay = loaderDisplay;
		
		//this.auth = new Auth();
		
		this.headers = {};
		this.headers['Content-Type'] = "application/json";	
		this.setAuthorization(Auth.getAccessToken());
	}
	
	setAuthorization(token){
		//this.headers['x-access-token'] = `Bearer ${token}`;
		this.headers['x-access-token'] = token;
	}
	
	setContentType($type){
		this.headers['Content-Type'] = $type
	}
	
	async post(uri,data,isPrivate = true) {
		return await this.apiCall("post", uri, data, isPrivate);
	}
	
	async get(uri,data,isPrivate = true) {
		return await this.apiCall("get", uri, data, isPrivate);
	}
	
	async put(uri,data,isPrivate = true) {
		return await this.apiCall("put", uri, data, isPrivate);
	}
	
	async del(uri,data,isPrivate = true) {
		return await this.apiCall("delete", uri, data, isPrivate);
	}
	
	
	async apiCall(method,uri,data = null,isPrivate = true) {
		
		if(this.loaderDisplay){
			loader.show();
		}
		
		var config = {
			    method,
			    url: `${API_URL}/${uri}`
			};
		
		if(method === 'get'){
			config["params"] = data;
		}
		if(data){
			config["data"] = JSON.stringify(data);
		}
		  
		if(isPrivate) {
			config["headers"] = this.headers;
		}
		else{
			config["headers"] = this.headers;
			delete config["headers"]['x-access-token'];
			
		}
		//console.log("config", config);
		var r =  await axios(config);
		
		loader.hide();
		return r;
			  
	}
	
	
	
}


