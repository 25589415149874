import React, {  } from 'react';

const PaginationItem = (props) => {
	
	if(String(props.currentPage) === String(props.page)){
		return <li className="page-item active"><button className="page-link" value={props.page}>{props.page}</button></li>;
	}
	else{
		return <li className="page-item"><button onClick={props.clickHandler} className="page-link" value={props.page}>{props.page}</button></li>;
	}
	  
}

const PaginationIndex = (props) => {
	return (Number(props.pagination.limit) * (Number(props.pagination.page)-1)) + (props.index+1);
}


const Pagination = (props) => {
 
  let pagination = props.pagination;
  let clickHandler = props.clickHandler;
  let currentPage = Number(pagination.page);
  
  let paginationItems = [];
  
  let total_page = Math.ceil(Number(pagination.total) / Number(pagination.limit));
  
  
  let start_page = 1;
  let end_page = currentPage + 4;
 
  if(currentPage >= 5){
	  start_page = currentPage - 4;
  }
  
  if(total_page >= end_page){
	  total_page = end_page;
  }
  
 
  
  for (var i=start_page; i <= total_page; i++) {
	  paginationItems.push(i);
  }
  
  return (
   
     <ul className="pagination mb-0">
     
      {pagination.page > 1 &&
        <li className="page-item"><button onClick={clickHandler} className="page-link" value={(currentPage - 1)}>Previous</button></li>
      }
      
      {paginationItems.map((page) => <PaginationItem clickHandler={clickHandler} key={page} currentPage={pagination.page} page={page} />)}
   
      {pagination.page < total_page &&
          <li className="page-item"><button onClick={clickHandler} className="page-link" value={(currentPage + 1)}>Next</button></li>
      }
      </ul>
    
  );
}
          
export default Pagination;
export {PaginationIndex};