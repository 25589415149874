import React, {  } from 'react';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import WithNav from "./layouts/WithNav";
import WithoutNav from "./layouts/WithoutNav";
import { RedtonsComponent } from "./components/RedtonsComponent";

import NotFoundComponent from "./components/NotFoundComponent";

import LoginComponent from "./components/LoginComponent";
import RegisterComponent from "./components/RegisterComponent";
import DashboardComponent from "./components/DashboardComponent";
import UserListComponent from "./user/UserListComponent";
import ProfileComponent from "./user/ProfileComponent";

import IncidentListComponent from "./incident/IncidentListComponent";
import IncidentFormComponent from "./incident/IncidentFormComponent";
import IncidentFormEditComponent from "./incident/IncidentFormEditComponent";

import BasicIncidentListComponent from "./incident/BasicIncidentListComponent";
import BasicIncidentFormComponent from "./incident/BasicIncidentFormComponent";
import BasicIncidentFormEditComponent from "./incident/BasicIncidentFormEditComponent";

const getBasename = path => path.substr(0, path.lastIndexOf('/'));


export default class AppComponent extends RedtonsComponent {
  
  constructor(props) {
    super(props);
   
    this.isLoggedIn = false;
    this.userRole = 0;
    
    if(this.auth.isLoggedIn()){
    	this.isLoggedIn = true;
    	this.profile = this.auth.getUser();
	    this.userRole = this.profile['role'];
    }
    //console.log("App state before : ",this.auth.isUserRole());
    this.state = {isLoggedIn:this.isLoggedIn};
    //console.log("App state after : ",this.state);
    
    this.pathname = window.location.pathname;
    this.splitLocation = this.pathname.split("/");
    
    this.setCurrentUri = this.setCurrentUri.bind(this);
    
  }
  
  
  componentDidMount(){
		this.setState({current_uri:this.splitLocation[1]})
	}
  
  
  setCurrentUri(){
		this.pathname = window.location.pathname;
		
	    this.splitLocation = this.pathname.split("/");
	    
	    this.setState({current_uri:this.splitLocation[1]})
	    
	}
  
	render() {
		  return (
		    <>
		      <div id="app" className="app">
		        <Router basename={getBasename(window.location.pathname)}>
		          <Routes>
		          
		         
		            <Route element={<WithoutNav />} > 
		                <Route path="/" element={<LoginComponent />} />
		            	<Route path="/login" element={<LoginComponent />} />
		            	<Route path="/register" element={<RegisterComponent />} />
		            </Route>
		              
		           <Route element={ <this.auth.PrivateRoute> <WithNav current_uri={this.state.current_uri}/> </this.auth.PrivateRoute> }>
			            
				        <Route path="/dashboard" element={<DashboardComponent />}/>
				   </Route>
				        
				        
		            <Route element={ <this.auth.PrivateRoute> <WithNav /> </this.auth.PrivateRoute> }>
		            
				        
				        <Route path="/profile" element={<ProfileComponent />} />
				       
				        <Route path="/basicIncident" element={<BasicIncidentListComponent/>} />
				        <Route path="/basicIncident/:id" element={<BasicIncidentFormEditComponent/>} />
				        
				        <Route path="/incident" element={<IncidentListComponent/>} />
				        <Route path="/incident/:id" element={<IncidentFormEditComponent/>} />
				        
				     
				        <Route path="*" element={<NotFoundComponent />} />
				    </Route>
				        
				    <Route element={ <this.auth.PrivateUserRoute> <WithNav /> </this.auth.PrivateUserRoute> }>
				    	
			        	<Route path="/basicIncident/create" element={<BasicIncidentFormComponent />} />
			        	<Route path="/incident/create" element={<IncidentFormComponent />} />
		        	
				    </Route>
			        	
		        	<Route element={ <this.auth.PrivateAdminRoute> <WithNav /> </this.auth.PrivateAdminRoute> }>
		        		<Route path="/user" element={<UserListComponent />} />
		        	</Route>
				           
		            
		          </Routes>
		        </Router>
		      </div>
		    </>
		  );
	}

}
