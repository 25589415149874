import React, { } from "react";
//import ReactDOM from "react-dom"

import { createRoot } from 'react-dom/client';

const CONTAINER_REF = document.createElement("div");

let load = false;

const root = createRoot(CONTAINER_REF);


function show(callback = () => {}) {
	
	  document.body.appendChild(CONTAINER_REF)
	  load = true;
	  root.render(
			<div className="loader-box"><div className="loader"></div></div>
	  )
	  
}


function hide() {
  if (load) {
	  root.render(<></>)
	  document.body.removeChild(CONTAINER_REF);
	  load = false;
  }
}


const loader = {
	  show,
	  hide
	}

export default loader
