import React, {  } from 'react';
import { Link } from "react-router-dom"; 
import { RedtonsComponent } from "../components/RedtonsComponent";
import IncidentForm1Component from "./IncidentForm1Component";
import IncidentForm2Component from "./IncidentForm2Component";
import IncidentForm3Component from "./IncidentForm3Component";
import IncidentForm4Component from "./IncidentForm4Component";
import IncidentForm5Component from "./IncidentForm5Component";




import Accordion from 'react-bootstrap/Accordion'


export default class BasicIncidentFormEditComponent extends RedtonsComponent {
  
  //let http = new Http();
 
	constructor(props) {
		super(props);
		
		this.profile = this.auth.getUser();
		
		const date = new Date().toISOString().substr(0, 10);
		const time = new Date().toISOString().substr(11, 5);
		
		
		this.state = {
		    date:date,
		    time:time,
			data:{
				occurrence_datetime: date+" "+time+":00",
				occurrence_status:'Draft'
			},
			wasValidated:false,
			errors:{},
			errorMessage:'',
		    successMessage:'',
		    update:false,
		    activeId:2,
		    fields:{},
		    isLoading:true,
		    complete:0,
		    //currentForm:2,
		    tempCurrentForm:1,
		    isEditableUser:1
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
		
		this.handleOnSelectPanel = this.handleOnSelectPanel.bind(this);
		this.handleOnSave = this.handleOnSave.bind(this); // save and continue button
		
		
		//get current id from url
		const pathArray = window.location.pathname.split('/');
        this.id = pathArray[2];
        
	}
	
	
	componentDidMount(){
		//this.getFields();
		this.getIncident();
		this.setState({id:this.id});
	}
	
	
	
	getFields(){
		this.http.get("fields")
		.then((res) => {
			if (res.data) {
				//console.log("Get fields : ",res.data)
				this.setState({"fields":res.data})
				setTimeout(this.setState({"isLoading":false}), 3000);
				
				this.updateProcessBar();
				
			}
		});
	}
	
	updateProcessBar(){
		let temp =  0;
		if(this.state.data['occurrence_datetime'] !== ""){
			temp = temp+10;
		}
		
		if(this.state.data['occurrence_status'] !== ""){
			temp = temp+10;
		}
		
		this.setState({complete: temp});
	}
	
	getIncident(){
		this.http.get("incident/"+this.id)
		.then((res) => {
			if (res.data.id) {
				const data = res.data;
				
				if(data['user_id'] !== this.profile['id']){
					this.setState({isEditableUser:0});
				}
					
				
				delete data['id'];
				delete data['user_id'];
				delete data['created_at'];
				delete data['updated_at'];
				
				const tempData = this.state.data;
				
				
				for (let key of Object.keys(data)) {
					if(data[key] === null){
						delete data[key];
					}
					else{
						tempData[key] = data[key];
					}
					
					if(key === 'occurrence_datetime'){
						this.setState({date: data[key].substr(0, 10),time: data[key].substr(11, 5)});
						tempData[key] = data[key].replace("T", " ").substr(0, 19);
					}
				}
				//console.log("Data : ",tempData);
				
				this.setState({data:tempData});
				
				this.getFields();
				
				
			}
		});
	}
	
	
	
	
	handleSubmit(event) {
		  //console.log("Current handle : ", event);
	      event.preventDefault();
	     
	      if(this.id){
	    	  let data = this.state.data
	    	  let step = data.step;
	    	  
	    	  if(step === "occurrence_description"){
	    		  data['occurrence_status'] = "Publish for Final Report";
	    		  this.setState({data:data});	  
	    	  }
	    	  
	    	  
	    	  
	    	  this.http.put("incident/"+this.id,this.state.data)
				.then((res) => {
					this.setState({wasValidated:true,errors:{}});
					if (res.data.id) {
						this.setState({update:true,id:res.data.id});
						//console.log("updated : ",this.state.tempCurrentForm);
						this.setState({currentForm: this.state.tempCurrentForm});
						
						//console.log("success")
					}
					else{
						 return false;
					}
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.data);
						this.setState({errors:error.response.data});
						this.setState({wasValidated:true});
						this.loader.hide();
					}
					return false;
				});
	      }
	  }
	
	
	handleOnSelectPanel(event){
		this.setState({currentForm:event});
	}
	
	/*move to next form step*/
	handleOnSave(event){
		this.setState({tempCurrentForm: event.target.value});
		
		const data = this.state.data;
		
		const t = this.state.fields['step'+(parseInt(event.target.value)-1)].key
		
	    data['step'] = t;
	    
	    this.setState({
	    	data:data
	    });
	}
	
	//input type value update
	handleChange(event) {
		if(this.state.isEditableUser === 0){
			return false;
		}
		
		
		   //console.log(event.target);
		   const value = event.target.value;
		   const name = event.target.name;
		   const data = this.state.data;
		   data[name] = value;
		   this.setState({
		      data:data
		   });
	  }
	
	//input type date and time update
	handleDateTimeChange(event) {
		if(this.state.isEditableUser === 0){
			return false;
		}
		
		   const value = event.target.value;
		   const name = event.target.name;
		   
		   this.setState({
			      [name]: value
			   });
		   
		   const data = this.state.data;
		   
		   if(name === "date"){
			   data['occurrence_datetime'] = value+" "+this.state.time+":00";
		   }
		   else if(name === "time"){
			   data['occurrence_datetime'] = this.state.date+" "+value+":00";
		   }
		   
		   //console.log("update datetime : ",data['occurrence_datetime'])
		  
		   this.setState({
			      data:data
			   });
	  }
	
	
  render() {
	  return (
			  
			  <div id="content" className="app-content">
				
			    <div className="container">
			
				    <div className="row justify-content-center">
				    
				   
				    <div className="col-xl-12">
				    
				    
					    <ul className="breadcrumb">
						    <li className="breadcrumb-item"><Link to="/basicIncident">BASIC INCIDENTS</Link></li>
					    </ul>
					 
					    <h1 className="page-header">
					    	VIEW/EDIT FORM
					    </h1>
					    
					    <div className="progress" style={{display:"none"}}>
				    	  <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" style={{width:(this.state.complete+"%")}}>{this.state.complete}</div>
				    	</div>
				    	
				    	<br/> 
				    	
					    <form className="was-validated1" onSubmit={this.handleSubmit} ref={(response) => (this.componentRef = response)}>
					    	{(this.state.isLoading === false) &&
					    		
						    <Accordion defaultActiveKey="2" activeKey={(this.state.currentForm)?this.state.currentForm:"1"} onSelect={this.handleOnSelectPanel}>
						    	<IncidentForm1Component form={this} />
						    	<IncidentForm2Component form={this} />
						    	<IncidentForm3Component form={this} />
						    	<IncidentForm4Component form={this} />
						    	<IncidentForm5Component form={this} last={true}/>
						    </Accordion>
					    	}
					    </form>	 	
					    	
					    	
					    
					</div>
				
				    </div>
			
			    </div>
		
		    </div>
			
	  );
  }
}
