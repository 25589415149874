import React, {  } from "react";
import { Link } from "react-router-dom"; 
import { RedtonsComponent } from "./RedtonsComponent";
//import loader from "../components/LoaderComponent";
import RegisterSuccessComponent from "./RegisterSuccessComponent";



export default class RegisterComponent  extends RedtonsComponent {
  
	constructor(props) {
	    super(props);
	    
	    this.state = {
	    		first_name: "",
	    		last_name: "",
	    		username: "",
	    		email: "",
	    		phone: undefined,
	    		password: "",
	    		confirm_password: "",
	    		role: 1,
	    		status: 1,
	    		agree: "",
	    		wasValidated:false,
	    		errors:{},
	    		isRegistered:false
	    };
	    
	    //this.wasValidated = true;
	    this.handleChange = this.handleChange.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    
	  }
	
	handleChange(event) {

		   const value = event.target.value;
		   const name = event.target.name;
		   
		   this.setState({
		      [name]: value
		   });
	  }
	  
	  
	  handleSubmit(event) {
	      event.preventDefault();
	      this.setState({wasValidated:true});
	      let data = {
		    		first_name: this.state.first_name,
		    		last_name: this.state.last_name,
		    		username: this.state.username,
		    		email: this.state.email,
		    		phone: this.state.phone,
		    		password: this.state.password,
		    		confirm_password: this.state.confirm_password,
		    		//agree: this.state.agree,
	    	    };
	      
	      this.http.post("register",data)
			.then((res) => {
				if (res.data.id) {
					this.setState({isRegistered:true});
				}
				else{
					return false;
				}
			})
			.catch((error) => {
				if (error.response) {
					//console.log(error.response.data);
					this.setState({errors:error.response.data});
					this.loader.hide();
				}
				return false;
			});
	  }
	
	  
	  
	  //etErrorMessage
	  // <form className={(this.state.wasValidated ? "was-validated1" : "")} onSubmit={this.handleSubmit}>
  
	  render(){
		  return (
		    <div id="app" className="app app-full-height app-without-header">
		    
		    
		    
		    <div className="register">
		
		    <div className="register-content">
		   
		    {this.state.isRegistered && 
		    	<RegisterSuccessComponent/>
		    }
		    {!this.state.isRegistered && 
		    <form className="was-validated1" onSubmit={this.handleSubmit}>
		    
		    <div><img src="assets/img/airmatrix_login_logo.png" style={{width:"360px","margin-bottom":"20px"}} alt="AirMatrix"/></div>
            
			    
			    <h1 className="text-center">Sign Up</h1>
			    
			   
			    
			    <div className="mb-3">
			    	<label className="form-label">First Name <span className="text-danger">*</span></label>
			    	<input type="text" className={"form-control form-control-lg bg-white bg-opacity-5 "+ this.isValid('first_name')}  name="first_name" value={this.state.first_name} onChange={this.handleChange} />
			    	{!this.isError('first_name') && <div className="invalid-feedback">{this.getErrorMessage('first_name')}</div>}
			    </div>
			    	
			    <div className="mb-3">
			    	<label className="form-label">Last Name <span className="text-danger">*</span></label>
			    	<input type="text" className={"form-control form-control-lg bg-white bg-opacity-5 "+ this.isValid('last_name')}  name="last_name" value={this.state.last_name} onChange={this.handleChange} />
			    	{!this.isError('last_name') && <div className="invalid-feedback">{this.getErrorMessage('last_name')}</div>}
			    </div>
			    	
			    <div className="mb-3">
			    	<label className="form-label">Username <span className="text-danger">*</span></label>
			    	<input type="text" className={"form-control form-control-lg bg-white bg-opacity-5 "+ this.isValid('username')}  name="username" value={this.state.username} onChange={this.handleChange} />
			    	{!this.isError('username') && <div className="invalid-feedback">{this.getErrorMessage('username')}</div>}
			    </div>
			    	
			    <div className="mb-3">
			    	<label className="form-label">Email Address<span className="text-danger">*</span></label>
			    	<input type="text" className={"form-control form-control-lg bg-white bg-opacity-5 "+ this.isValid('email')}  name="email"  value={this.state.email} onChange={this.handleChange} />
			    	{!this.isError('email') && <div className="invalid-feedback">{this.getErrorMessage('email')}</div>}
			    </div>	
			    	
			    <div className="mb-3">
			    	<label className="form-label">Phone</label>
			    	<input type="text" className={"form-control form-control-lg bg-white bg-opacity-5 "+ this.isValid('phone')}  name="phone" value={this.state.phone} onChange={this.handleChange} />
			    	{!this.isError('phone') && <div className="invalid-feedback">{this.getErrorMessage('phone')}</div>}
			    </div>	
			
			
			    <div className="mb-3">
			    	<label className="form-label">Password <span className="text-danger">*</span></label>
			    	<input type="password" className={"form-control form-control-lg bg-white bg-opacity-5 "+ this.isValid('password')}  name="password" value={this.state.password} onChange={this.handleChange} />
			    	{!this.isError('password') && <div className="invalid-feedback">{this.getErrorMessage('password')}</div>}
			    </div>
			    	
			    <div className="mb-3">
			    	<label className="form-label">Confirm Password <span className="text-danger">*</span></label>
			    	<input type="password" className={"form-control form-control-lg bg-white bg-opacity-5 "+ this.isValid('confirm_password')}  name="confirm_password" value={this.state.confirm_password} onChange={this.handleChange} />
			    	{!this.isError('confirm_password') && <div className="invalid-feedback">{this.getErrorMessage('confirm_password')}</div>}
			    </div>
			    	
			    
			    <div className="mb-3" style={{display:'none'}}>>
			    	<div className="form-check">
			    		<input className="form-check-input" id="agreeCheck" type="checkbox" checked={this.state.agree} onChange={(e) => (e.target.checked)?this.setState({agree:"Yes"}):this.setState({agree:""})}  />
			    		<label className="form-check-label" htmlFor="agreeCheck">
			    				I have read and agree to the <Link to="/register">Terms of Use</Link> and <Link to="/register">Privacy Policy</Link>.
			    		</label>
			    	</div>
			    </div>
			    
			    <div className="mb-3">
			    	<button type="submit" className="btn btn-outline-theme btn-lg d-block w-100">Sign Up</button>
			    </div>
			    
			    <div className="text-white text-opacity-50 text-center">
			    	Already registered user? <a href="/login">Sign In</a>
			    </div>
			    
			</form>
		    }
		    </div>
		
		    </div>
		    
		    
		    </div>
		  );
	  }
}
