import React, {  } from 'react';
//import { Link } from "react-router-dom"; 
import { RedtonsComponent } from "./../components/RedtonsComponent";
//import ModalComponent from "../components/ModalComponent";
import UserFormComponent from "./UserFormComponent";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Pagination,{PaginationIndex} from "./../components/PaginationComponent";


export default class UserListComponent extends RedtonsComponent {
  
  //let http = new Http();
 
  constructor(props) {
    super(props);
    
    
    this.state = {
      users: [],
      pagination:[],
      errors:{},
      user:{},
      params:{
    	  page_limit:10,
    	  current_page:1,
    	  search:''
      }
    };
    
    this.deleteUser = this.deleteUser.bind(this);
    this.getList = this.getList.bind(this);
    this.getSingleView = this.getSingleView.bind(this);
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    
    this.paginate = this.paginate.bind(this);   
  }

  
  componentDidMount(){
	  this.getList();
  }
  
  
  handleChange(event) {

	   const value = event.target.value;
	   const name = event.target.name;
	   
	   let params = this.state.params;
	   
	   params[[name]] = value;
	   
	   this.setState({
		   params:params
	   });
  }
  
  resetForm(){
	  let params = this.state.params;
	  
	  params['search'] = '';
  
	  this.setState({
		   params:params
	   });
  }
   
  handleSubmit(event) {
      event.preventDefault();
      this.getList();
  }
  
  paginate(event){
	  let params = this.state.params;
	  params.current_page = event.target.value;
	  
	  this.setState({params:params});
	  
	  this.getList();
  }
  
  getList(){
	 
	  this.setState({users:[],pagination:[]});
	 
	  
	  this.http.get("user",this.state.params)
		.then((res) => {
			this.setState({users: res.data.data});
			this.setState({pagination: res.data.pagination});
		})
		.catch((error) => {
			this.loader.hide();
			if (error.response) {
				this.setState({errors:error.response.data});
				
			}
			return false;
		});
  }
  
  
  getSingleView(id){
	  
	   this.http.get("user/"+id)
		.then((res) => {
			this.setState({user: res.data});
			this.modalOpen();
		})
		.catch((error) => {
			this.loader.hide();
			if (error.response) {
				this.setState({errors:error.response.data});
			}
			return false;
		});
  }
  
  deleteUser(id){
	  
	  function del(){
		  //alert('Click Yes');
		  this.http.del("user/"+id)
			.then((res) => {
				this.getList();
			})
			.catch((error) => {
				this.loader.hide();
				if (error.response) {
					this.setState({errors:error.response.data});
					
				}
				return false;
			});
	  }
	  
	  const options = {
			  title: 'Are you sure?',
			  message: 'Do you want delete?',
			  buttons: [
			    {
			      label: 'Yes',
			      onClick: () => del()
			    },
			    {
			      label: 'No',
			      onClick: () => {}
			    }
			  ],
			  closeOnEscape: true,
			  closeOnClickOutside: true,
			  keyCodeForClose: [8, 32]
			};
	  confirmAlert(options);
  }
  
  render() {
	  return (
	    
	    <div id="content" className="app-content">
	
		    <div className="container">
		
			    <div className="row justify-content-center">
			    
			   
			    <div className="col-xl-12">
			    
			   
				    <UserFormComponent data={this.state.user} getList={this.getList} show={this.state.show} modalClose={this.modalClose}/>
				    
				    <h1 className="page-header">
				    	USER MANAGER 
				    	&nbsp;<button type="button" className="btn btn-default btn-sm" onClick={this.modalOpen}><i className="bi bi-plus" /> Register New User</button>
				    </h1>
				    
				  
				    	
				    <div className="row">
				    
						    <div className="card">
						   
						    <div className="card-body">
						    
							    <form className="was-validated1 search-form" onSubmit={this.handleSubmit}>
								    <div className="input-group mb-3"> 
			                            <div className="input-group-preppend">
			                            	<button type="button" className="btn btn-success btn-sm" onClick={this.getList}><i className="bi bi-arrow-clockwise"></i></button>
			                            </div>
			                            
			                            <input type="text" name="search" value={this.state.params.search} onChange={this.handleChange} className="form-control form-select-sm" placeholder="Search by Name, username and email ..."/>
			                            
			                            <div className="input-group-append">
			                              <button type="submit" className="btn btn-success btn-sm"><i className="fa fa-search"></i> Search</button>
			                              <button type="submit" className="btn btn-secondary btn-sm" onClick={this.resetForm}>Clear</button>
			                            </div>
			                            
			                        </div>
		                        </form>
                          
		                        
		                        <div className="table-responsive">
                       
								    <table className="table table-dark table-hover">
								    <thead className="">
								      <tr>
								        <th scope="col"></th>
								        <th scope="col">NAME</th>
								        <th scope="col">USERNAME</th>
								        <th scope="col">EMAIL</th>
								        <th scope="col">PHONE</th>
								        <th scope="col">STATUS</th>
								        <th scope="col"></th>
								      </tr>
								    </thead>
								    <tbody>
								    
								    {this.state.users.map((row, index) => (
								      <tr key={row.id}>
								        <th scope="row"><span className="badge bg-secondary"><PaginationIndex pagination={this.state.pagination} index={index}/></span></th>
								        <td>{row.first_name} {row.last_name}</td>
								        <td>{row.username}</td>
								        <td>{row.email}</td>
								        <td>{row.phone}</td>
								        <td>{row.status && <span className="badge bg-success">Active</span> } { !row.status && <span className="badge bg-success">InActive</span> }</td>
								        <td>
								        	<button type="button" className="btn btn-default btn-sm"  onClick={() => this.getSingleView(row.id)}> <i className="bi bi-pencil" /> </button>
								        	<button type="button" className="btn btn-danger btn-sm" onClick={() => this.deleteUser(row.id)}> <i className="bi bi-trash" /></button>
								        </td>
								      </tr>
								    ))}
								    </tbody>
								  </table>
								</div>
								
								
								<Pagination pagination={this.state.pagination} clickHandler={this.paginate}/>
							
						    
						    </div>
						    
						   
						    <div className="card-arrow">
						      <div className="card-arrow-top-left"></div>
						      <div className="card-arrow-top-right"></div>
						      <div className="card-arrow-bottom-left"></div>
						      <div className="card-arrow-bottom-right"></div>
						    </div>
						  </div>
						
						
				
				    </div>
				
				</div>
			
			    </div>
		
		    </div>
	
	    </div>
    
    
	  );
  }
}
