import React, {  } from "react";
import { Modal } from "react-bootstrap";
import { RedtonsComponent } from "../components/RedtonsComponent";

export default class MessageModalComponent extends RedtonsComponent {
  constructor(props) {
    super(props);
    
    //this.setState({messageShow:true,successMessage:'dsd',errorMessage:''});
    
    this.state = {
    		messageShow:this.props.messageShow,
    		successMessage:this.props.successMessage,
    		errorMessage:this.props.errorMessage
	};
    
    /*if(this.props.messageShow != undefined){
    	
    	this.setState({messageShow:this.props.messageShow});
    }
    
    if(this.props.successMessage != undefined){
    	this.setState({successMessage:this.props.successMessage});
    }
    
    
    if(this.props.errorMessage != undefined){
    	this.setState({errorMessage:this.props.errorMessage});
    }*/
    
    this.messageModalOpen = this.messageModalOpen.bind(this);
    this.messageModalClose = this.messageModalClose.bind(this);
    
    if(this.props.messageModalOpen !== undefined){
    	this.messageModalOpen = this.props.messageModalOpen;
    }
    if(this.props.messageModalClose !== undefined){
    	this.messageModalClose = this.props.messageModalClose;
    }
    
    this.setErrorMessageModal = this.setErrorMessageModal.bind(this);
	this.setSuccessMessageModal = this.setSuccessMessageModal.bind(this);
	//console.log("updated",this.state);
  } 
  
  
  messageModalOpen(){
	  this.setState({messageShow:true});
	  
  }
  messageModalClose(){
	  this.setState({messageShow:false});
  }
  setErrorMessageModal(errorMessage){
	this.setState({errorMessage:errorMessage});
  }
  setSuccessMessageModal(successMessage){
	this.setState({successMessage:successMessage});
  }
  
  componentDidMount() {
	  
  }
 
  componentDidUpdate(prevProps) {
	  
	  if(prevProps.messageShow !== this.props.messageShow) {
	    this.setState({messageShow: this.props.messageShow});
	  }
	  
	  if(prevProps.errorMessage !== this.props.errorMessage) {
		  this.setState({errorMessage:this.props.errorMessage});
	  }
	  
	  if(prevProps.successMessage !== this.props.successMessage) {
		  this.setState({successMessage:this.props.successMessage});
	  }
	  
	  //console.log("message modal updated true....",this.state);
  }
  
  render() {
    return (
      <div>
        <Modal show={this.state.messageShow} animation={true} size="lg" className="shadow-lg messageModal">
        
        {this.state.errorMessage && 
	    	<div className="alert alert-danger fade show p-3">
            	<h4 className="alert-heading">Error!</h4>
            	<p>{this.state.errorMessage}</p>
            	<button type="button" className="btn btn-default btn-sm" onClick={this.messageModalClose}>OK</button>
            </div>
        }
	    
	    {this.state.successMessage && 
	    	<div className="alert alert-success fade show p-3">
            	<h4 className="alert-heading">Success!</h4>
            	<p>{this.state.successMessage}</p>
            	<button type="button" className="btn btn-default btn-sm" onClick={this.messageModalClose}>OK</button>
            </div>
        }
          
        </Modal>	
        </div>   
      
    );
  }
}