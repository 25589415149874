import React, {  } from 'react';
import { Link } from "react-router-dom"; 
import { RedtonsComponent } from "../components/RedtonsComponent";
//import Moment from 'react-moment';

import { saveAs } from 'file-saver';
import {pdf} from "@react-pdf/renderer";
import PdfComponent from "./PdfComponent";

import Pagination,{PaginationIndex} from "./../components/PaginationComponent";

const generateLetterPdf = async (id,fields,data) => {
	
	const blob = await pdf(
			<PdfComponent id={id} fields={fields} data={data} type="advance"/>
	).toBlob();
	
	saveAs(blob, id+"-report.pdf");
};



export default class IncidentListComponent extends RedtonsComponent {
  
  //let http = new Http();
 
  constructor(props) {
    super(props);
    this.profile = this.auth.getUser();
    this.userRole = this.profile['role'];
    
    
    this.state = {
      incidents: [],
      pagination:[],
      errors:{},
      incident:{},
      fields:[],
      params:{
    	  page_limit:10,
    	  current_page:1,
    	  form_type:'advance',
    	  occurrence_status:'',
    	  start_date:'',
    	  end_date:'',
    	  search:''
      },
      users:{}
    };
    
    //this.deleteUser = this.deleteUser.bind(this);
    this.getList = this.getList.bind(this);
    this.getSingleView = this.getSingleView.bind(this);
    
    this.handleChange = this.handleChange.bind(this);
    
    this.paginate = this.paginate.bind(this);   
    
  }

  componentDidMount(){
	  this.getUserList();
	  
	  this.getList();
	  
	  this.getFields();
  }
  
  
  getUserList(){
		 
	  this.setState({users:{}});
	 
	  let users = {};
	  
	  this.http.get("user/list")
		.then((res) => {
			
			res.data.forEach(row => {
				users[row.id] = row.first_name+" "+row.last_name;
			});
			
			this.setState({users: users});
		})
		.catch((error) => {
			this.loader.hide();
			if (error.response) {
				this.setState({errors:error.response.data});
				
			}
			return false;
		});
  }
  
  getFields(){
		this.http.get("fields")
		.then((res) => {
			if (res.data) {
				//console.log("Get fields : ",res.data)
				this.setState({"fields":res.data})
				setTimeout(this.setState({"isLoading":false}), 3000);
				
				//this.updateProcessBar();
				
			}
		});
	}
  
  handleChange(event) {

	   const value = event.target.value;
	   const name = event.target.name;
	   
	   let params = this.state.params;
	   
	   if(name === 'occurrence_status' && params[[name]] === value){
		   params[[name]] = '';
	   }else{
		   params[[name]] = value;
	   }
	   
	   this.setState({
		   params:params
	   });
	   
	   if(name === 'occurrence_status'){
		   this.getList();
	   }
	   
  }
  
  paginate(event){
	  let params = this.state.params;
	  params.current_page = event.target.value;
	  
	  this.setState({params:params});
	  
	  this.getList();
  }
  
  getList(){
	  
	  this.setState({incidents:[],pagination:[]});
	  
	  this.http.get("incident",this.state.params)
		.then((res) => {
			this.setState({incidents: res.data.data});
			this.setState({pagination: res.data.pagination});
		})
		.catch((error) => {
			this.loader.hide();
			if (error.response) {
				this.setState({errors:error.response.data});
				
			}
			return false;
		});
  }
  
  
  getSingleView(id){
	  
	   //let id = event.target.id;
	   //alert(event.target.id);
	   //console.log(event.target,id);
	   
	   this.http.get("incident/"+id)
		.then((res) => {
			//console.log("Get single user : ",res.data);
			this.setState({incident: res.data});
			this.modalOpen();
		})
		.catch((error) => {
			this.loader.hide();
			if (error.response) {
				this.setState({errors:error.response.data});
			}
			return false;
		});
  }
  
  getDateFormt(str){
	  return str.replace("T"," ").slice(0, -8) 
  }
  
 
  
  render() {
	  return (
	    
	    <div id="content" className="app-content">
	
		    <div className="container">
		
			    <div className="row justify-content-center">
			    
			   
			    <div className="col-xl-12">
			    
			   
			    
				    <h1 className="page-header">
				    	DETAILED INCIDENTS 
				    	&nbsp;<button type="button" className="btn btn-success btn-sm" onClick={this.getList}><i className="bi bi-arrow-clockwise" /></button>
				    	&nbsp;
				    	{this.userRole === "2" && 
				    		<Link to={"/incident/create"} className="btn btn-default btn-sm"><i className="bi bi-plus" /> New</Link>
				    	}
				    </h1>
				    
				  
				    	
				    <div className="row">
				    
						    <div className="card">
						   
						    <div className="card-body">
						     
						    <div className="input-group"> 
	                        	<div className="input-group-preppend">
	                        		<button type="button" className="btn btn-success btn-sm" onClick={this.getList}><i className="bi bi-arrow-clockwise"></i></button>
	                        	</div>
			                    
	                        	<div className="input-group-preppend">
			                    	<button type="button" className="btn btn-default btn-sm" name="occurrence_status" value="Draft" onClick={this.handleChange}>
			                    		{this.state.params.occurrence_status === 'Draft' && <i className="fa fa-check"></i>} Draft
			                    	</button>
			                    </div>
			                
			                    <div className="input-group-preppend">
			                    	<button type="button" className="btn btn-success btn-sm" name="occurrence_status" value="Publish for Final Report" onClick={this.handleChange}>
			                    	{this.state.params.occurrence_status === 'Publish for Final Report' && <i className="fa fa-check"></i>} Completed</button>
			                    </div>
			                    
			                </div>
			                
						    <div className="table-responsive">
								    <table className="table">
								    <thead className="table-light">
								      <tr>
								        <th scope="col"></th>
								        <th scope="col">INCIDENT NUMBER</th>
								        {this.userRole === "1" &&
								        	<th scope="col">USER</th>
								        }
								        <th scope="col">DATETIME</th>
								        <th scope="col">STATUS</th>
								        <th scope="col"></th>
								        <th scope="col"></th>
								      </tr>
								    </thead>
								    <tbody>
								    
								    {this.state.incidents.map((row, index) => (
								      <tr key={row.id}>
								        <th scope="row"><span className="badge bg-secondary"><PaginationIndex pagination={this.state.pagination} index={index}/></span></th>
								        <td>{row.id}</td>
								        {this.userRole === "1" &&
								        	<td>{this.state.users[row.user_id]}</td>
								        }
								        <td>{this.getDateFormt(row.occurrence_datetime)}</td>
								        <td>{row.occurrence_status}</td>
								        <td><button className="btn btn-warning btn-sm" onClick={() => generateLetterPdf(row.id,this.state.fields,row)}><i className="bi bi-download" /></button></td>
								        <td>
								        	{this.userRole === "1" &&
								        	  <Link className="btn btn-default btn-sm" to={"/incident/"+row.id}><i className="bi bi-eye" /></Link>   
								        	}
								        	{this.userRole === "2" &&
									        	  <Link className="btn btn-default btn-sm" to={"/incident/"+row.id}><i className="bi bi-pencil" /></Link>   
									        	}
								        </td>
								      </tr>
								    ))}
								    </tbody>
								  </table>
						   </div>
						   
						   <Pagination pagination={this.state.pagination} clickHandler={this.paginate}/>
						    
						    
						    </div>
						    
						   
						    <div className="card-arrow">
						      <div className="card-arrow-top-left"></div>
						      <div className="card-arrow-top-right"></div>
						      <div className="card-arrow-bottom-left"></div>
						      <div className="card-arrow-bottom-right"></div>
						    </div>
						  </div>
						
						
				
				    </div>
				
				</div>
			
			    </div>
		
		    </div>
	
	    </div>
    
    
	  );
  }
}
