import React, { Component } from "react";
import { Link } from "react-router-dom"; 


export default class RegisterSuccessComponent extends Component {
 

 
 
  render() {
	  return (
			  <div className="error-page">

				  <div className="error-page-content">
				  
					  <div className="card mb-5 mx-auto" style={{maxWidth:'320px'}}>
					  
					  	<div className="card-body">
					  		<div className="card">
						  		<div className="error-code">OK</div>
						  		<div className="card-arrow">
							  		<div className="card-arrow-top-left"></div>
							  		<div className="card-arrow-top-right"></div>
							  		<div className="card-arrow-bottom-left"></div>
							  		<div className="card-arrow-bottom-right"></div>
							  	</div>
							</div>
					    </div>
					  
						<div className="card-arrow">
							  <div className="card-arrow-top-left"></div>
							  <div className="card-arrow-top-right"></div>
							  <div className="card-arrow-bottom-left"></div>
							  <div className="card-arrow-bottom-right"></div>
						</div>
						  
					  </div>
					 
					  <h1>SUCCESS</h1>
					  <h3>You are succesfully registered. Please click here for login <Link to="/login" className="text-decoration-none text-white text-opacity-50">Login</Link></h3>
					  <hr/>
					  
					  
				  </div>

			  </div>
    
	  );
  }
}
