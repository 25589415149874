import React, {  } from 'react';
import { RedtonsComponent } from "../components/RedtonsComponent";
import OCCURANCE_CATEGORY from "./category";
import { Accordion } from 'react-bootstrap'
import MyTextInput from "./formFieldComponent";

export default class IncidentForm2Component extends RedtonsComponent {
  
  //let http = new Http();
 
	constructor(props) {
		super(props);
		this.profile = this.auth.getUser();
	    this.userRole = this.profile['role'];
		
		const {form} = props;
		this.form = form;
		this.handleChange = this.handleChange.bind(this);
		
		this.state = {
				errors: {},
				wasValidated: false,
				title: this.form.state.fields.step2.title,
				fields: this.form.state.fields.step2.fields,
				step_key:this.form.state.fields.step2.key,
				form:this.form
		}
	
	}
	
	updateProcessBar(){
		let temp =  0;
		
		
		this.setState({complete: temp});
	}
	
	
	handleChange(event) {
		if(this.form.state.isEditableUser === 0){
			return false;
		}
		
		   const value = event.target.value;
		   const name = event.target.name;
		   const data = this.form.state.data;
		   
		   data[this.state.step_key][name] = value;
		   
		   
		   if(name === "injuries" || name === "rpas_damage"){
			   data[this.state.step_key]['the_categoty_is'] = this.getCategory();
		   }
		   
		   this.form.setState({
			      data:data
			   });
	  }
	
	getCategory(){
		
		const rpas_damage = this.form.state.data.occurrence_category.rpas_damage;
		const injuries = this.form.state.data.occurrence_category.injuries;
		
		return OCCURANCE_CATEGORY[rpas_damage+"+"+injuries];
	}
	
  render() {
	  return (
			  <Accordion.Item eventKey="2">
	        	<Accordion.Header>2. Occurrence Category</Accordion.Header>
		        <Accordion.Body className="row">
		         
		        <MyTextInput row={this.state.fields[0]} step={this.state.step_key} form={this.state.form} handleChange={this.handleChange}  />
		        <MyTextInput row={this.state.fields[1]} step={this.state.step_key} form={this.state.form} handleChange={this.handleChange} />
		        <MyTextInput row={this.state.fields[2]} step={this.state.step_key} form={this.state.form} handleChange={this.handleChange} />
        		
		        
			    {this.form.state.data.occurrence_category.the_categoty_is &&
			    <div className="mb-3 col-xl-12">
			    	<label className="form-label">The Category is <span className="text-danger">{this.form.state.data.occurrence_category.the_categoty_is}</span></label>
			    </div>
			    }
			   
		    	
			   
		        {this.userRole === "2" &&
		        	<div className="mb-3">
			    		<button type="submit" className="btn btn-outline-theme active" value="3" onClick={this.form.handleOnSave}>Save & Continue</button>
			    	</div>
		        }
		        </Accordion.Body>
	        </Accordion.Item>
	  );
  }
}
