import React, { Component } from "react";
import { Link } from "react-router-dom"; 


export default class NotFoundComponent extends Component {
 
 
 
  render() {
	  return (
			  <div className="error-page">

				  <div className="error-page-content">
				  
					  <div className="card mb-5 mx-auto" style={{maxWidth:'320px'}}>
					  
					  	<div className="card-body">
					  		<div className="card">
						  		<div className="error-code">404</div>
						  		<div className="card-arrow">
							  		<div className="card-arrow-top-left"></div>
							  		<div className="card-arrow-top-right"></div>
							  		<div className="card-arrow-bottom-left"></div>
							  		<div className="card-arrow-bottom-right"></div>
							  	</div>
							</div>
					    </div>
					  
						<div className="card-arrow">
							  <div className="card-arrow-top-left"></div>
							  <div className="card-arrow-top-right"></div>
							  <div className="card-arrow-bottom-left"></div>
							  <div className="card-arrow-bottom-right"></div>
						</div>
						  
					  </div>
					 
					  <h1>Oops!</h1>
					  <h3>We can't seem to find the page you're looking for</h3>
					  <hr/>
					  
					  <p className="mb-1">
					  Here are some helpful links instead:
					  </p>
					  
					  <p className="mb-5">
						  <Link to="/dashboard" className="text-decoration-none text-white text-opacity-50">Dashboard</Link>
						  
						  <span className="link-divider"></span>
						  
						  <Link to="/incident" className="text-decoration-none text-white text-opacity-50">Incidents</Link>
						  
						  <span className="link-divider"></span>
						  
						  <Link to="/user" className="text-decoration-none text-white text-opacity-50">User</Link>
						  
						  <span className="link-divider"></span>
						  
						  
						  <Link to="/settings" className="text-decoration-none text-white text-opacity-50">Settings</Link>
						  
					  </p>
					  
					  <Link to="" className="btn btn-outline-theme px-3 rounded-pill">
					  	<i className="fa fa-arrow-left me-1 ms-n1"> </i> Go Back
					  </Link>
				  </div>

			  </div>
    
	  );
  }
}
