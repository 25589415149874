const OCCURANCE_CATEGORY = {
		"DESTROYED or MISSING+FATAL": "A",	
		"DESTROYED or MISSING+VERY SERIOUS": "A",
		"DESTROYED or MISSING+SERIOUS": "A",
		"DESTROYED or MISSING+MINOR":"A",
		"DESTROYED or MISSING+NO INJURY":"A",
		
		"VERY SERIOUS+FATAL":"A",
		"VERY SERIOUS+VERY SERIOUS":"B",
		"VERY SERIOUS+SERIOUS":"B",
		"VERY SERIOUS+MINOR":"B",
		"VERY SERIOUS+NO INJURY":"B",
		
		"SERIOUS+FATAL":"A",
		"SERIOUS+VERY SERIOUS":"B",
		"SERIOUS+SERIOUS":"C",
		"SERIOUS+MINOR":"C",
		"SERIOUS+NO INJURY":"C",
		
		"MINOR+FATAL":"A",
		"MINOR+VERY SERIOUS":"B",
		"MINOR+SERIOUS":"C",
		"MINOR+MINOR":"C",
		"MINOR+NO INJURY":"D",
		
		"NO DAMAGE+FATAL":"A",
		"NO DAMAGE+SERIOUS":"C",
		"NO DAMAGE+MINOR":"D",
		"NO DAMAGE+NO INJURY":"E"	
	};


export default OCCURANCE_CATEGORY;