import React, {  } from 'react';
import ModalComponent from "../components/ModalComponent";
import MessageModalComponent from "../components/MessageModalComponent";

export default class UserFormComponent extends ModalComponent {
  
  //let http = new Http();
 
	constructor(props) {
		super(props);
		this.state = {
			data:{
				first_name: "",
				last_name: "",
				username: "",
				email: "",
				phone: "",
				password: "",
				confirm_password: "",
				status: 1,
				agree: ""
			},
			wasValidated:false,
			errors:{},
			errorMessage:'',
		    successMessage:'',
		    update:false
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.getList = this.props.getList;
		
	    
	}
	
	
    
	componentDidUpdate(prevProps) {
		
		
		  if(prevProps.show !== this.props.show) {
			  
		    this.setState({show: this.props.show});
		    
		    if(!this.props.show){
		    	this.setState({
			    	data:{
						first_name: "",
						last_name: "",
						username: "",
						email: "",
						phone: "",
						password: "",
						confirm_password: "",
						status: 1,
						agree: ""
					},
					update:false,wasValidated:false,errors:{}
			    })
		    }
		  }
		  
		  if(prevProps.data !== this.props.data) {
			    this.setState({data: this.props.data,update:true,wasValidated:false,errors:{}});
		  }
		  if(this.children !== this.props.children) {
			  this.children = this.props.children;
			  this.setState({children:this.children});
		  }
	}
	
	
	
	handleSubmit(event) {
	      event.preventDefault();
	      
	      let data = {
		    		first_name: this.state.data.first_name,
		    		last_name: this.state.data.last_name,
		    		username: this.state.data.username,
		    		email: this.state.data.email,
		    		phone: this.state.data.phone,
		    		password: this.state.data.password,
		    		confirm_password: this.state.data.confirm_password,
		    		//agree: this.state.agree,
	    	    };
	      
	      //console.log("upate user : ",data);
	      
	      if(this.state.update){
	    	  delete data.username;
	    	  delete data.password;
	    	  delete data.confirm_password;
	    	  
	    	  this.http.put("user/"+this.state.data.id,data)
				.then((res) => {
					this.setState({wasValidated:true});
					if (res.data.id) {
						  this.getList();
						  this.modalClose();
					      this.setSuccessMessageModal("User has been succesfully updated.");
					      this.messageModalOpen();
					}
					else{
						  //this.modalClose();
					      this.setErrorMessageModal("Server side error.");
					      this.messageModalOpen();
						  return false;
					}
				})
				.catch((error) => {
					if (error.response) {
						//console.log(error.response.data);
						this.setState({errors:error.response.data});
						this.setState({wasValidated:true});
						this.loader.hide();
					}
					return false;
				});
	      }
	      else{
	    	  this.http.post("user",data)
				.then((res) => {
					this.setState({wasValidated:true});
					if (res.data.id) {
						  this.getList();
						  this.modalClose();
					      this.setSuccessMessageModal("User has been succesfully registered.");
					      this.messageModalOpen();
					}
					else{
						  //this.modalClose();
					      this.setErrorMessageModal("Server side error.");
					      this.messageModalOpen();
						  return false;
					}
				})
				.catch((error) => {
					if (error.response) {
						//console.log(error.response.data);
						this.setState({errors:error.response.data});
						this.setState({wasValidated:true});
						this.loader.hide();
					}
					return false;
				});
	      }
	      return false;
	  }
	
	
	handleChange(event) {

		   const value = event.target.value;
		   const name = event.target.name;
		   const data = this.state.data;
		   data[name] = value;
		   this.setState({
		      data:data
		   });
	  }
	
  render() {
	  return (
			<div>
			<MessageModalComponent messageShow={this.state.messageShow} errorMessage={this.state.errorMessage} successMessage={this.state.successMessage} messageModalClose={this.messageModalClose}/>
		    	
			<ModalComponent show={this.state.show} modalClose={this.modalClose}>
			
			
		    <form className="was-validated1" onSubmit={this.handleSubmit}>
				<div className="modal-header">
			    	<h5 className="modal-title">User</h5>
			    	<button type="button" className="btn-close" onClick={this.modalClose}></button>
			    </div>
			    <div className="modal-body row">
			    
			    
			    <div className="mb-3 col-6">
			    	<label className="form-label">First Name <span className="text-danger">*</span></label>
			    	<input type="text" className={"form-control form-control-sm bg-white bg-opacity-5 "+ this.isValid('first_name')}  name="first_name" value={this.state.data.first_name} onChange={this.handleChange} />
			    	{!this.isError('first_name') && <div className="invalid-feedback">{this.getErrorMessage('first_name')}</div>}
			    </div>
		    	
			    <div className="mb-3 col-6">
			    	<label className="form-label">Last Name <span className="text-danger">*</span></label>
			    	<input type="text" className={"form-control form-control-sm bg-white bg-opacity-5 "+ this.isValid('last_name')}  name="last_name" value={this.state.data.last_name} onChange={this.handleChange} />
			    	{!this.isError('last_name') && <div className="invalid-feedback">{this.getErrorMessage('last_name')}</div>}
			    </div>
			    	
			    <div className="mb-3 col-6">
			    	<label className="form-label">Username <span className="text-danger">*</span></label>
			    	<input type="text" className={"form-control form-control-sm bg-white bg-opacity-5 "+ this.isValid('username')}  name="username" value={this.state.data.username} onChange={this.handleChange} />
			    	{!this.isError('username') && <div className="invalid-feedback">{this.getErrorMessage('username')}</div>}
			    </div>
			    	
			    <div className="mb-3 col-6">
			    	<label className="form-label">Email Address<span className="text-danger">*</span></label>
			    	<input type="text" className={"form-control form-control-sm bg-white bg-opacity-5 "+ this.isValid('email')}  name="email"  value={this.state.data.email} onChange={this.handleChange} />
			    	{!this.isError('email') && <div className="invalid-feedback">{this.getErrorMessage('email')}</div>}
			    </div>	
			    	
			    <div className="mb-3 col-12">
			    	<label className="form-label">Phone</label>
			    	<input type="text" className={"form-control form-control-sm bg-white bg-opacity-5 "+ this.isValid('phone')}  name="phone" value={this.state.data.phone} onChange={this.handleChange} />
			    	{!this.isError('phone') && <div className="invalid-feedback">{this.getErrorMessage('phone')}</div>}
			    </div>	
			
			    {this.state.update === false &&
			    <>
			    <div className="mb-3 col-6">
			    	<label className="form-label">Password <span className="text-danger">*</span></label>
			    	<input type="password" className={"form-control form-control-sm bg-white bg-opacity-5 "+ this.isValid('password')}  name="password" value={this.state.password} onChange={this.handleChange} />
			    	{!this.isError('password') && <div className="invalid-feedback">{this.getErrorMessage('password')}</div>}
			    </div>
			    	
			    
			    <div className="mb-3 col-6">
			    	<label className="form-label">Confirm Password <span className="text-danger">*</span></label>
			    	<input type="password" className={"form-control form-control-sm bg-white bg-opacity-5 "+ this.isValid('confirm_password')}  name="confirm_password" value={this.state.confirm_password} onChange={this.handleChange} />
			    	{!this.isError('confirm_password') && <div className="invalid-feedback">{this.getErrorMessage('confirm_password')}</div>}
			    </div>
			    </>
			    }
			    
			    
			    <div className="mb-3">
			    	<button type="submit" className="btn btn-outline-theme active">Save & Submit</button>
			    </div>
			    
			    </div>
			</form>	
			
		    </ModalComponent>
		    </div>
			
	  );
  }
}
