import React, {  } from 'react';
import { RedtonsComponent } from "../components/RedtonsComponent";
import Accordion from 'react-bootstrap/Accordion'


import MyTextInput from "./formFieldComponent";


export default class IncidentForm4Component extends RedtonsComponent {
  
  //let http = new Http();
	
	
	constructor(props) {
		super(props);
		this.profile = this.auth.getUser();
	    this.userRole = this.profile['role'];
		
		const {form} = props;
		this.form = form;
		this.handleChange = this.handleChange.bind(this);
		
		
		//this.step_key = "mission_description";
		
		this.state = {
				errors: {},
				wasValidated: false,
				title: this.form.state.fields.step4.title,
				fields: this.form.state.fields.step4.fields,
				step_key:this.form.state.fields.step4.key,
				form:this.form
		}
		
		//console.log("C key :",this.state.fields[6]);
		
	}
	
	
	
	handleChange(event) {
		if(this.form.state.isEditableUser === 0){
			return false;
		}
		   const value = event.target.value;
		   const name = event.target.name;
		   let data = this.form.state.data;
		   
		   if(event.target.type === "checkbox"){
			   
			   if(!data[this.state.step_key].hasOwnProperty(name)){
				   data[this.state.step_key][name] = []
			   }
			   
			   if(event.target.checked){
				   data[this.state.step_key][name].push(value);
			   }else{
				   
				   var index = data[this.state.step_key][name].indexOf(value); // get index if value found otherwise -1
				   if (index > -1) { //if found
					   data[this.state.step_key][name].splice(index, 1);
				   }
			   }
			   
		   }else{
			   data[this.state.step_key][name] = value;
		   }
		   
		   
		   this.form.setState({
			      data:data
			   });
	  }
	
	
    
	
  render() {
	  return (
			  <Accordion.Item eventKey="4">
	        	<Accordion.Header>4. {this.state.title}</Accordion.Header>
		        <Accordion.Body className="row">
		        
		       
		        {this.state.fields.map((row, index) => (
			        	
		        		<MyTextInput row={row} form={this.state.form} key={index} step={this.state.step_key} handleChange={this.handleChange} subField={false}>
			        	
			        	  {row.fields &&
			        		  
			        		  (row.fields.map((row1, index) => (	
			        				  
			        				  <MyTextInput row={row1} form={this.state.form} key={index} index={index} step={this.state.step_key} handleChange={this.handleChange} subField={true} parent={row}/>
			        		 
			        		  ))) 
			        		  
			        	  }
			        	  
			        	  </MyTextInput>
					      
				))}

		        {this.userRole === "2" &&
		        <div className="mb-3">
			    	<button type="submit" className="btn btn-outline-theme active" value="5" onClick={this.form.handleOnSave}>Save & Continue</button>
			    </div>
		        }
		        
		        
		        </Accordion.Body>
	        </Accordion.Item>
	  );
  }
}
