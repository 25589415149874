import React, {  } from "react";
import { Modal } from "react-bootstrap";
import { RedtonsComponent } from "../components/RedtonsComponent";

export default class ModalComponent extends RedtonsComponent {
  constructor(props) {
    super(props);
    
    this.state = {show:this.props.show,children:this.props.children};
    
    /*if(this.props.show != undefined){
    	this.setState({show:this.props.show});
    }
    */
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
    
    if(this.props.modalClose !== undefined){
    	this.modalClose = this.props.modalClose;
    }
    if(this.props.modalOpen !== undefined){
    	this.modalOpen = this.props.modalOpen;
    }
    
    /*this.children = this.props.children;
    this.setState({children:this.children});*/
    
    //console.log("modal open....",this.state,this.props);
  } 
  
  
  modalOpen(){
	  this.setState({show:true});
	  this.setState({errors:{}});
  }
  modalClose(){
	  this.setState({show:false});
	  this.setState({errors:{}});
  }
  
 
  componentDidUpdate(prevProps) {
	  if(prevProps.show !== this.props.show) {
		  
	    this.setState({show: this.props.show});
	  }
	  if(this.children !== this.props.children) {
		  this.children = this.props.children;
		  this.setState({children:this.children});
	  }
  }
  
  render() {
    return (
      <div>
        <Modal show={this.state.show} animation={true} size="lg" className="shadow-lg">
        
          {this.state.children}
          
        </Modal>	
        </div>   
      
    );
  }
}