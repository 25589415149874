import React, {  } from 'react';



export default function Footer(props){
	 
	let current_uri = props.current_uri;
	
	let temp_current_uri = window.location.pathname;
	
	temp_current_uri = temp_current_uri.split("/");
	
	current_uri = temp_current_uri[1];
	
	
    
	//console.log("Footer : ", current_uri);
	
  return (
		 
    <>
      <footer>
      <div id="content" className="app-content">
	    <div className="container">
	
		    <div className="row justify-content-center">
		    <div className="col-xl-12 powered">
		    	{current_uri === 'dashboard' &&
		    	<>
				    <h3>Partners</h3>
		    		<ul>
		    			<li><img src="/assets/img/copa_logo.png" alt=""/></li>
		    			<li><img src="/assets/img/airmatrix_logo.png" alt=""/></li>
		    			<li><img src="/assets/img/aerial_evolution_logo.png" alt=""/></li>
		    		</ul>
	    		</>
		    	}
		    	<div className="copyright">&#169; 2022 | Powered by AirMatrix </div>
		    </div>
		    
		    </div>
		</div>
      </div>
      </footer>
    </>
  );
};
