import { Component } from "react";

import { Http } from "../services/http";
import Auth, {  } from "../services/auth.service";

import loader from "./LoaderComponent";

class RedtonsComponent extends Component {



	constructor(props) {
		super(props);
		this.http = new Http();
		this.auth = new Auth(this.http);
		this.loader = loader;
		this.state = {
				wasValidated:false,
				errors:{}
		};

		this.modalOpen = this.modalOpen.bind(this);
		this.modalClose = this.modalClose.bind(this);
		
		this.messageModalOpen = this.messageModalOpen.bind(this);
		this.messageModalClose = this.messageModalClose.bind(this);
		
		this.setErrorMessageModal = this.setErrorMessageModal.bind(this);
		this.setSuccessMessageModal = this.setSuccessMessageModal.bind(this);
	}


	modalOpen(){
		this.setState({show:true});
		this.setState({errors:{}});
	}
	modalClose(){
		this.setState({show:false});
		this.setState({errors:{}});
	}
	
	
	messageModalOpen(){
		this.setState({messageShow:true});
		//this.setState({errors:{}});
	}
	messageModalClose(){
		this.setState({messageShow:false});
		//this.setState({errors:{}});
	}
	
	setErrorMessageModal(errorMessage){
		this.setState({errorMessage:errorMessage});
	}
	setSuccessMessageModal(successMessage){
		this.setState({successMessage:successMessage});
	}

	isValid(key){
		
		//console.log("check is valid : ",this.state.errors,key);
		if(this.state.errors[key]){
			return "is-invalid";
		}
		else if(this.state.wasValidated){
			return "is-valid";
		}
		else{
			return '';
		}
		
	}
	isError(key){
		
		if(this.state.errors[key]){
			return false;
		}
		else if(this.state.wasValidated){
			return true;
		}
		else{
			return false;
		}
	}

	getErrorMessage(key){
		if(this.state.errors[key]){
			return this.state.errors[key]['message'];
		}
		return '';
	}

}

export {RedtonsComponent};

