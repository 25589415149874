import React, {  } from 'react';
import { RedtonsComponent } from "./../components/RedtonsComponent";


class ProfileComponent extends RedtonsComponent {
  
 
  constructor(props) {
    super(props);
    
    this.state = {
    		first_name: "",
    		last_name: "",
    		username: "",
    		email: "",
    		phone: "",
    		password: "",
    		confirm_password: "",
    		agree: "",
    		profileSubmit:false,
    		passwordSubmit:false,
    		errors:{}
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    
    
    this.profile = this.auth.getUser();
    //console.log(this.profile.id);
    
    
  }
  
  componentDidMount(){
	  this.getProfile();
  }

  updateState(data){
	  this.setState(data);
  }
  getProfile(){
	  this.http.get("user/"+this.profile.id)
		.then((res) => {
			this.updateState({
				first_name: res.data.first_name,
	    		last_name: res.data.last_name,
	    		username: res.data.username,
	    		email: res.data.email,
	    		phone: res.data.phone
			});
		})
		.catch((error) => {
			if (error.response) {
				//this.setState({errors:error.response.data});
				this.loader.hide();
			}
			return false;
		});
	  
	  
	  
  }
  
  
  handleChange(event) {

	   const value = event.target.value;
	   const name = event.target.name;
	   
	   this.setState({
	      [name]: value
	   });
  }
  
  async handleProfileSubmit(event) {
      event.preventDefault();
      this.setState({wasValidated:true});
      
      let data = {
	    		first_name: this.state.first_name,
	    		last_name: this.state.last_name,
	    		//username: this.state.username,
	    		email: this.state.email,
	    		phone: this.state.phone
    	    };
      
      await this.http.put("user/"+this.profile.id,data)
		.then((res) => {
			if (res.data) {
				this.setState({
					first_name: res.data.first_name,
					last_name: res.data.last_name,
		    		username: res.data.username,
		    		email: res.data.email,
		    		phone: res.data.phone,
				});
				this.setState({profileSubmit:true});
				return true;
			}
			else{
				return false;
			}
		})
		.catch((error) => {
			this.loader.hide();
			if (error.response) {
				//setIsLoggedIn(true);
			}
			
			return false;
		});
  }
  
  
  async handlePasswordSubmit(event) {
      event.preventDefault();
      this.setState({wasValidated:true});
      let data = {
    		  	password: this.state.password,
	    		confirm_password: this.state.confirm_password
    	    };
      
      await this.http.put("user/"+this.profile.id,data)
		.then((res) => {
			if (res.data) {
				this.setState({
					password: "",
		    		confirm_password: ""
				});
				this.setState({passwordSubmit:true});
				this.setState({wasValidated:false});
				this.setState({errors:{}});
				return true;
			}
			else{
				return false;
			}
		})
		.catch((error) => {
			this.loader.hide();
			this.setState({
				password: "",
	    		confirm_password: ""
			});
			if (error.response) {
				//console.log(error.response);
				if(error.response.data.error){
					this.setState({errors:{"password":{"message":"The password is required","rule":"required"},"confirm_password":{"message":"The confirm password can not be less than 6.","rule":"required"}}});
				}
				else{
					this.setState({errors:error.response.data});
				}
			}
			//console.log("After submit error : ",this.state);
			return false;
		});
  }
 
  
  render() {
	  return (
	    
	    <div id="content" className="app-content">
	
		    <div className="container">
		
			    <div className="row justify-content-center">
			    
			    
			    <div className="col-xl-12">
			
				    <ul className="breadcrumb">
				    	<li className="breadcrumb-item active">PROFILE</li>
				    </ul>
				    
				 
			    
				    <div className="row">
				    		
						    <div className="card col-xl-6">
						    	<div className="card-header fw-bold small">Profile</div>
						    	<div className="card-body">
						    	
						    	{ this.state.profileSubmit && 
						            <div className="alert alert-success fade show p-3">
							            <h4 className="alert-heading">Success!</h4>
							            <p>Your profile has been updated succefully!</p>
							        </div>
						         }
						    	
						    	<form className="was-validated1" onSubmit={this.handleProfileSubmit}>
								    <div className="mb-3">
								    	<label className="form-label">First Name <span className="text-danger">*</span></label>
								    	<input type="text" className="form-control form-control-lg bg-white bg-opacity-5" name="first_name" value={this.state.first_name} onChange={this.handleChange} />
								    </div>
								    	
								    <div className="mb-3">
								    	<label className="form-label">Last Name <span className="text-danger">*</span></label>
								    	<input type="text" className="form-control form-control-lg bg-white bg-opacity-5" name="last_name" value={this.state.last_name} onChange={this.handleChange} />
								    </div>
								    	
								    	
								    <div className="mb-3">
								    	<label className="form-label">Username <span className="text-danger">*</span></label>
								    	<input type="text" className="form-control form-control-lg bg-white bg-opacity-5" value={this.state.username} onChange={this.handleChange} />
								    </div>
								    	
								    <div className="mb-3">
								    	<label className="form-label">Email Address<span className="text-danger">*</span></label>
								    	<input type="text" className="form-control form-control-lg bg-white bg-opacity-5" name="email" value={this.state.email} onChange={this.handleChange} />
								    </div>	
								    	
								    <div className="mb-3">
								    	<label className="form-label">Phone</label>
								    	<input type="text" className="form-control form-control-lg bg-white bg-opacity-5"name="phone" value={this.state.phone} onChange={this.handleChange} />
								    </div>	
								    	
								    <div className="mb-3">
								    	<button type="submit" className="btn btn-theme btn-lg d-block w-100">Save</button>
								    </div>
							    </form> 
								</div>
						    
						   
							    <div className="card-arrow">
							      <div className="card-arrow-top-left"></div>
							      <div className="card-arrow-top-right"></div>
							      <div className="card-arrow-bottom-left"></div>
							      <div className="card-arrow-bottom-right"></div>
							    </div>
						    </div>
						    
						    
						    <div className="card col-xl-6">
						    	<div className="card-header fw-bold small">Password Change</div>
						    	<div className="card-body">
						      
						    	{ this.state.passwordSubmit && 
						            <div className="alert alert-success fade show p-3">
							            <h4 className="alert-heading">Success!</h4>
							            <p>Your password has been changed succefully!</p>
							        </div>
						         }
						    	
						    	<form className="was-validated1" onSubmit={this.handlePasswordSubmit}>
							    	<div className="mb-3">
								    	<label className="form-label">Password <span className="text-danger">*</span></label>
								    	<input type="password" className={"form-control form-control-lg bg-white bg-opacity-5 "+ this.isValid('password')} name="password" value={this.state.password} onChange={this.handleChange} />
								    	{!this.isError('password') && <div className="invalid-feedback">{this.getErrorMessage('password')}</div>}
								    </div>
								    	
								    <div className="mb-3">
								    	<label className="form-label">Confirm Password <span className="text-danger">*</span></label>
								    	<input type="password" className={"form-control form-control-lg bg-white bg-opacity-5 "+ this.isValid('confirm_password')} name="confirm_password" value={this.state.confirm_password} onChange={this.handleChange} />
								    	{!this.isError('confirm_password') && <div className="invalid-feedback">{this.getErrorMessage('confirm_password')}</div>}
								    </div>
								    	
								    <div className="mb-3">
								    	<button type="submit" className="btn btn-theme btn-lg d-block w-100">Change Password</button>
								    </div>
								</form>    
								</div>
						    
						   
							    <div className="card-arrow">
							      <div className="card-arrow-top-left"></div>
							      <div className="card-arrow-top-right"></div>
							      <div className="card-arrow-bottom-left"></div>
							      <div className="card-arrow-bottom-right"></div>
							    </div>
						    </div>
						
						  
				
				    </div>
				    
				    
				    
				    
				
				</div>
			
			    </div>
		
		    </div>
	
	    </div>
    
    
	  );
  }
}

export default ProfileComponent;
