import React, {  } from 'react';
import { RedtonsComponent } from "../components/RedtonsComponent";
//import Moment from 'react-moment';
import {
	  Document,
	  Page,
	  StyleSheet,
	  Text,
	  View,
	  Image
	} from "@react-pdf/renderer";
	

	
const style = StyleSheet.create({
  page: {
    display: 'flex',
    margin:0,
    fontSize: '11px',
    fontWeight: '600',
    color: '#000',
    marginTop:20,
    marginBottom:20,
  },
  
  heading: {
	    margin:20,
	    display: 'flex',
	    flexDirection: 'row',
	    padding:0,
	    fontSize: '11px',
	    fontWeight: '600',
	    color: '#000',
 },

  view: {
	    marginLeft:20,
	    marginRight:20,
	    display: 'flex',
	    flexDirection: 'row',
	    padding:0,
	    fontSize: '11px',
	    fontWeight: '600',
	    color: '#000',
	    border:'1px solid #35434a'
  },
  
  textLeft:{
	  backgroundColor:'#35434a',
	  flex: '1 auto',
	  padding:5,
	  color:'#fff',
	  fontWeight: '700',
  },
  text: {
	    flex: '1 1 auto',
	    padding:5
  }
})



const PrintStep = (props) => {

	const {number, step, data} = props;
	
	console.log(props)
	
	function getValue(value){
		
		if(Array.isArray(value)){
			let temp_value = value;
			let type = 'string';
			
			value.forEach((row1) => {
					//console.log("Return 1 : ",row1)
				    type = typeof(row1);
			});
			
			if(type === "string"){
				console.log(temp_value);
				return temp_value.join(', ');
				
			}else{
				return ('');
			}
		}
		else{
			console.log(value);
			return value;
		}
		
	}
	

	return (
			<>
			<View style={style.heading}>
	        	<Text>{number}. {step.title}</Text>
	        </View>
	        
	        
	        {step.fields.map((row, index) => (
	          <View key={step.key+index}>
					
				{!(row.name === "cause_factors" || row.name === "preventative_measures" || row.name === "human_factors")&& (
		        <View key={step.key+index} style={style.view}>
		            <Text style={style.textLeft}>{row.title}</Text>
		            <Text style={style.text}>{ getValue(data[row.name]) }</Text> 
		        </View>)
				}
		          
				{row.fields &&( (row.name === "cause_factors" || row.name === "preventative_measures" || row.name === "human_factors") ? (
					data[row.name].map((value,key)=>(
						row.fields.map((row1, index1) => (
							<View key={step.key+index1} style={style.view}>
								<Text style={style.textLeft}>{row1.title}</Text>
								<Text style={style.text}>{ value[row1.name] }</Text>
						  	</View>
						))
					))

				):(	
					row.fields.map((row1, index1) => (	
						data[row1.name] && 
						<View key={step.key+index1} style={style.view}>
							<Text style={style.textLeft}>{row1.title}</Text>
							<Text style={style.text}>{ getValue(data[row1.name]) }</Text>
						  </View>
			   
						)
					)
				))
				}
	          
	          </View>
	          
	        ))}
			</>
	);
}

export default class PdfComponent extends RedtonsComponent {
  
  //let http = new Http();
 
	constructor(props) {
		super(props);
		
		this.id = props.id;
		this.type = props.type;
		//console.log(props.fields,props.data)
		
		this.state = {
			data:props.data,
			fields:props.fields,
			wasValidated:false,
			errors:{},
			errorMessage:'',
		    successMessage:'',
		    update:false
		};
	}
	
	getDateFormt(str){
		  return str.replace("T"," ").slice(0, -8) 
	  }
	
  render() {
	  return (
			  <Document>
		        <Page size="A4" style={style.page} wrap>
		        
		        <View style={style.heading}>
		        	<Image src="/assets/img/airmatrix_login_logo.jpg" style={{width:"360px"}}/>
		        </View>
		        
		        <View style={style.heading}>
		        	<Text>1. {this.state.fields.step1.title}</Text>
		        </View>
		     
		         <View style={style.view}>
		            <Text style={style.textLeft}>Incident Id</Text>
		            <Text style={style.text}>{this.state.data['id']}</Text>
		          </View>
	          
		          <View style={style.view}>
		            <Text style={style.textLeft}>Occurrence Date & Time</Text>
		            <Text style={style.text}>{this.getDateFormt(this.state.data['occurrence_datetime'])}</Text>
		          </View>
		          
		          <View style={style.view}>
		            <Text style={style.textLeft}>Occurrence Status</Text>
		            <Text style={style.text}>{this.state.data['occurrence_status']}</Text>
		          </View>
		      
		        
		        <PrintStep key="2" number="2" step={this.state.fields.step2} data={this.state.data[this.state.fields.step2.key]} />
		        
		        </Page>
		        
		        <Page size="A4" style={style.page} wrap>
		        
		        <PrintStep key="3" number="3" step={this.state.fields.step3} data={this.state.data[this.state.fields.step3.key]} />
		        
		       
	            <PrintStep number="4" step={this.state.fields.step4} data={this.state.data[this.state.fields.step4.key]} />
		        
	            <PrintStep number="5" step={this.state.fields.step5} data={this.state.data[this.state.fields.step5.key]} />
		        
	            </Page>
	            
	            {this.type === 'advance' && 
	            	<>
	            		<Page size="A4" style={style.page} wrap>
			        
			            <PrintStep number="6" step={this.state.fields.step6} data={this.state.data[this.state.fields.step6.key]} />
				        
			            <PrintStep number="7" step={this.state.fields.step7} data={this.state.data[this.state.fields.step7.key]} />
				        
			            </Page>
			            
				        <Page size="A4" style={style.page} wrap>
				        
			            <PrintStep number="8" step={this.state.fields.step8} data={this.state.data[this.state.fields.step8.key]} />
			            
			            <PrintStep number="9" step={this.state.fields.step9} data={this.state.data[this.state.fields.step9.key]} />
			            
			            <PrintStep number="10" step={this.state.fields.step10} data={this.state.data[this.state.fields.step10.key]} />
			            </Page>
			       </>
	            }
		      </Document>
			
	  );
  }
}
