import React, {  } from 'react';
import { RedtonsComponent } from "../components/RedtonsComponent";
import Accordion from 'react-bootstrap/Accordion'


import MyTextInput from "./formFieldComponent";


export default class IncidentForm6Component extends RedtonsComponent {
  
  //let http = new Http();
	
	
	constructor(props) {
		super(props);
		this.profile = this.auth.getUser();
	    this.userRole = this.profile['role'];
		
		const {form} = props;
		this.form = form;
		this.handleChange = this.handleChange.bind(this);
		this.handlePersonChange = this.handlePersonChange.bind(this);
		
		this.handleDecrese = this.handleDecrese.bind(this);
		this.handleIncrease = this.handleIncrease.bind(this);
		//this.changeSubField = this.changeSubField.bind(this);
		
		
		//const human_factors = {human_factors:"",description:""}
		//this.step_key = "mission_description";
		
		this.state = {
				errors: {},
				wasValidated: false,
				title: this.form.state.fields.step7.title,
				fields: this.form.state.fields.step7.fields,
				step_key:this.form.state.fields.step7.key,
				form:this.form
		}
		
		if(this.form.state.data[this.state.step_key]['persons'].length === 0){
			const name = 'persons';
			let data = this.form.state.data;
			let temp = data[this.state.step_key][name];
			
			temp.push({});
			
			data[this.state.step_key][name] = temp;
			
			this.form.setState({
			      data:data
			   });
		}
		
		
		
	}
	
	
	
	handleChange(event) {
		if(this.form.state.isEditableUser === 0){
			return false;
		}
		   const value = event.target.value;
		   const name = event.target.name;
		   let data = this.form.state.data;
		   
		   if(event.target.type === "checkbox"){
			   
			   if(!data[this.state.step_key].hasOwnProperty(name)){
				   data[this.state.step_key][name] = []
			   }
			   
			   if(event.target.checked){
				   data[this.state.step_key][name].push(value);
			   }else{
				   
				   var index = data[this.state.step_key][name].indexOf(value); // get index if value found otherwise -1
				   if (index > -1) { //if found
					   data[this.state.step_key][name].splice(index, 1);
				   }
			   }
			   
		   }else{
			   data[this.state.step_key][name] = value;
		   }
		   
		   
		   this.form.setState({
			      data:data
			   });
	  }
	
	handlePersonChange(event) {
		if(this.form.state.isEditableUser === 0){
			return false;
		}
		   const value = event.target.value;
		   const name = event.target.name;
		   const index = event.target.id;
		   
		   let data = this.form.state.data;
		   
		   let temp = data[this.state.step_key]['persons'];
		   if(temp.length > index) {
			     //console.log("Human defiened");
			     temp[index][name] = value;
		   }
		   else {
			   temp.push({[name]:value});
		   }
		   data[this.state.step_key]['persons'] = temp;
		   
		   
		   this.form.setState({
			      data:data
			   });
    }
	
	handleDecrese(event){
		if(this.form.state.isEditableUser === 0){
			return false;
		}
		const name = event.currentTarget.dataset.name;
		let data = this.form.state.data;
		let temp = data[this.state.step_key][name];
		
		if(temp.length > 1){
			temp.pop();
			
			data[this.state.step_key][name] = temp;
			
			this.form.setState({
			      data:data
			   });
		}
	}
	
	handleIncrease(event){
		if(this.form.state.isEditableUser === 0){
			return false;
		}
		const name = event.currentTarget.dataset.name;
		
		let data = this.form.state.data;
		let temp = data[this.state.step_key][name];
		
		temp.push({});
		
		data[this.state.step_key][name] = temp;
		
		this.form.setState({
		      data:data
		   });
		
	}
	 
	
    
	
  render() {
	  return (
			  <Accordion.Item eventKey="7">
	        	<Accordion.Header>7. {this.state.title}</Accordion.Header>
		        <Accordion.Body className="row">
		        
		        
		        {this.state.fields.map((row, index) => (
			        	
		        		<MyTextInput row={row} form={this.state.form} key={index} step={this.state.step_key} handleChange={this.handleChange} subField={false}/>
					      
				))}

		        
		        <div className="mb-4 col-xl-12">
		        	<h3>Persons
		        		<span className="text-danger" style={{cursor:"pointer",'padding-left':"10px"}} data-name="persons" onClick={this.handleDecrese}>-</span> | 
		        		<span className="text-danger" style={{cursor:"pointer"}} data-name="persons" onClick={this.handleIncrease}>+</span>
		        	</h3>
		        </div>
		      
		        
		        {this.form.state.data[this.state.step_key]['persons'].map((row, index) => (
		        		
		        	<>  <hr/>
		        	
		        	{this.state.fields[6].fields.map((row1, index1) => (
		        			<MyTextInput row={row1} form={this.state.form} index={index}  parentName="persons" step={this.state.step_key} handleChange={this.handlePersonChange} subField={false}/>
		        	))}	
		        		
		            </>
		        ))}
		        
		        
		       
		        {this.userRole === "2" &&
		        <div className="mb-3">
			    	<button type="submit" className="btn btn-outline-theme active" value="10" onClick={this.form.handleOnSave}>Save & Continue</button>
			    </div>
		        }
		        
		        
		        </Accordion.Body>
	        </Accordion.Item>
	  );
  }
}
